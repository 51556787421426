import { YoutubeFilled } from "../../../../node_modules/@ant-design/icons/lib/icons/index";
import { Drawer } from "../../../../node_modules/antd/es/index";


export const VideoDrawer = ({video, onFinish}) => {    

    return (
        <Drawer
            closeIcon={null}
            onClose={onFinish}
            placement='right'
            width={'90%'}
            open={true}
            style={{ background: 'linear-gradient(to bottom, rgba(46, 199, 255, 1), rgba(5, 159, 255, 1))', color: 'white' }}
        >

            <h1><YoutubeFilled/> Tutoriales</h1>
            <b>{video.title}</b>
            <br/><br/>
            
            <iframe 
                width="100%"             
                height="90%"             
                src={video.url}
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; 
                autoplay; 
                clipboard-write; 
                encrypted-media; 
                gyroscope; 
                picture-in-picture; 
                web-share" allowfullscreen>                
            </iframe>           

        </Drawer>
    )
}