import { EditOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { InfoView } from '../InfoView';
import { EditDescription } from './EditDescription';

export const Description = ({location}) => {
    
    const [edit, setEdit] = useState(false);

    return (
        <div>        
            <span  onClick={() => setEdit(true)}>
                <b className="title-box">Descripción <EditOutlined /></b>
            </span>
            {
                edit ? 
                <EditDescription
                description={location?.description}                 
                setEdit={setEdit} 
                edit={edit}                 
                /> : 
                
               <InfoView data={location?.description}/>
            }                        
        </div>
    )
}
