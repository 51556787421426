import { Card} from "antd";
import "../../styles/Register.css"
import { Button, Result } from "../../../node_modules/antd/es/index";
import { useEffect, useState } from "react";
import { ShopFilled, ShopOutlined, SyncOutlined } from "../../../node_modules/@ant-design/icons/lib/icons/index";
import { Link } from "../../../node_modules/react-router-dom/dist/index";

export const Welcome = () => {

    const [updateData, setUpdateData] = useState(false);

    useEffect(()=>{

        setTimeout(() => setUpdateData(!updateData), 15000);

        },[])

    return (
        <div>
            <Card style={{margin:'30px 15px', padding:'0px'}}>
                <Result
                    status="success"                
                    title="Bienvenido a LOCALIZA.ME"
                    subTitle="En unos segundos toda tu información estará sincronizada"
                    extra={[
                    <>
                        {
                        updateData ? 
                            <Link to={"/"}>
                                <Button size='large' type="primary" key="console">
                                    <ShopOutlined/><b> Empezar</b>
                                </Button>
                            </Link>
                            :
                            <h3><SyncOutlined spin/> Sincronizando</h3>
                        }                        
                    </>
                    ]}
                />
            </Card>        
        </div>
    )
}

