import { DatePicker, Form } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FormItemEvent = () => {		

  const { t } = useTranslation();

  return (		
	<div style={{ display: 'flex', justifyContent: 'space-around', margin: '10px 0', alignItems: 'center' }}>
		<Form.Item label={<b>Fecha Inicio</b>} name='dateRangeStart' rules={[{ required: true, message: 'Falta la fecha de Inicio' }]}>
			<DatePicker/>		
		</Form.Item>  	

		<Form.Item label={<b>Fecha Final</b>} name='dateRangeEnd' rules={[{ required: true, message: 'Falta la fecha final' }]}>
			<DatePicker/>		
		</Form.Item>  	
	</div>
	)
}

export default FormItemEvent;