import { Modal, Tabs, Tooltip } from 'antd';
import React, { useState } from 'react';
import UploadInput from '../../../components/molecules/Forms/UploadInput';
import {InfoCircleOutlined} from "@ant-design/icons"

const UpdtPicsModal = ({
  visible,
  handleVisible,
  locationId,
  textBtn
}) => {

  const [activeTabKey, setActiveTabKey] = useState('1');

  const [categoryPic, setCategoryPic] = useState('LOGO')
  const [textPic, setTextPic] = useState('Logo')

  const handleTabChange = (key) => {
    setActiveTabKey(key);
    if (key === '1') {
      setTextPic('Logo');
      setCategoryPic('LOGO')
    } else if (key === '2') {
      setTextPic('Portada');
      setCategoryPic('COVER')
    }
  };

  const resolutionLogo = ' mínimo 120 x 120; máximo 5200 x 5300.';
  const resolutionCover = ' mínimo 480 x 270; máximo 2120 x 1192.';

  return (
    <>
      <Modal
        visible={visible}
        footer={null}
        width={450}
        onCancel={() => handleVisible(false)}
      >

        <Tabs
          defaultActiveKey="1"
          type="line"
          activeKey={activeTabKey}
          onChange={handleTabChange}
        >
          <Tabs.TabPane tab={<span>Logo</span>} key="1" />
          <Tabs.TabPane tab={<span>Portada</span>} key="2" />
        </Tabs>

        <div style={{display:'flex', justifyContent:'space-between'}}>
        <h2 style={{ paddingTop: '5px', paddingBottom: '10px', paddingRight: '15px' }}>Editar Image {textPic}</h2>
        <Tooltip title={ <><h3>Requerimientos:</h3><div>
            <b>Formato:</b> JPG o PNG.<br />
            <b>Tamaño:</b> entre 10 KB y 5 MB.<br />
            <b>Resolución:</b>
            {categoryPic === "LOGO" ? resolutionLogo : resolutionCover}
          </div></>}><InfoCircleOutlined /></Tooltip>
        </div>

       

        <UploadInput
          selectedKeys={[locationId]}
          onFinish={() => handleVisible(false)}
          textBtn={textBtn}
          category={categoryPic}
        />
      </Modal>
    </>
  );
};

export default UpdtPicsModal