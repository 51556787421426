import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonLZ } from '../atoms/ButtonLZ';

const PublishButton = ({ to, onClick, type }) => {
    return (
        <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end', right: '10px', bottom: '70px' }}>
            <Link to={to}>
                <ButtonLZ style={{ backgroundColor: 'white', color: 'dodgerblue', borderColor: 'dodgerBlue' }}>
                    Cancelar
                </ButtonLZ>
            </Link>
            <ButtonLZ onClick={onClick}>
                Publicar {type}
            </ButtonLZ>
        </div>
    );
};

export default PublishButton;