import { Form, Input, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { ButtonLZ } from '../../atoms/ButtonLZ';
import { useAuth } from '../../../store/AuthContext/AuthContext';
import { authRequest } from '../../../common/request/authRequest';
import { getProjectStorage } from '../../../store/storageContext';
import IAFilled from '../../../assets/IA_logo.png';
import { Badge, Button, Collapse, Image } from '../../../../node_modules/antd/es/index';
import { ArrowUpOutlined, ClockCircleOutlined, LockFilled } from '../../../../node_modules/@ant-design/icons/lib/icons/index';
import { useIsAuthorized } from '../../../common/constants/AuthUtils';
import { GlobalVariableContext } from '../../../store/GlobalVariableContext/GlobalVariableContext';

const FormItemSummary = ({isRequired , form, type }) => {

	const { t } = useTranslation();
	const { groupAccount } = useAuth();
	const [isLoading, setIsLoading] = useState(false);
	const [phrase, setPhrase] = useState('');
	const {setPlansDrawer } = useContext(GlobalVariableContext)
	const isAuthorized = useIsAuthorized();

	const getResponseIa = async () => {
		setIsLoading(true)
		await authRequest(`${process.env.REACT_APP_API}/projects/` + getProjectStorage() + `/${groupAccount}` + `/post?prompt=${encodeURIComponent(`
		 .El contenido de la publicación es para \`\`\`${type}\`\`\`
		 .El tema de la publicación tiene es sobre: \`\`\`${phrase}\`\`\``)}`, {}).then((data) => {
			handleOpenTextArea(data)
			setIsLoading(false)
		}).catch((error) => {
			setIsLoading(false)
		});
	}
	

	const handleOpenTextArea = (responseText) => {
        form.setFieldsValue({ summary: responseText });
    };

	return (

		<div>		
			<Form.Item
				label={<b>Texto principal</b>}
				name={'summary'}
				rules={[{ required: isRequired, message: 'El detalle es obligatorio'}]}
				style={{marginBottom:'3px'}}
			>
				<Input.TextArea rows={4} placeholder={'Contenido principal de la publicación..'} />
			</Form.Item>
			<div style={{ display: 'flex', justifyContent: 'space-around', margin: '10px 0', alignItems: 'center' }}>
				<ArrowUpOutlined style={{color:'purple'}}/>
				<ArrowUpOutlined style={{color:'purple'}}/>
				<ArrowUpOutlined style={{color:'purple'}}/>
			</div>
			
			<Collapse ghost>	
				{				
				<Collapse.Panel key="1" style={{background:'Lavender', margin:'15px 0px'}}
				disabled={!isAuthorized(['ROLE_APP_AI'])} 
				header={
					<div>					
						<h3 style={{color:'purple'}}>							
							{!isAuthorized(['ROLE_APP_AI']) ? 
								<>																		
									<span><LockFilled style={{fontSize:'22px'}}/>  Asistente virtual</span>
									<div style={{ display: 'flex', justifyContent: 'end', marginTop:'15px'}}>										
										<Button
											onClick={() => setPlansDrawer(true)}                      
											type='link'
											style={{ color: 'purple', borderColor: 'purple', backgroundColor: 'white' }}
											size='small'>
												<Image preview={false} style={{height:'22px', width:'22px', opacity: 1}} src={IAFilled} /> 
												<b>Módulo IA</b>
										</Button> 
									</div>
								</>
								:
								<>
									<Image preview={false} style={{height:'22px', width:'22px', opacity: 1}} src={IAFilled} /> 
									<span><LockFilled style={{fontSize:'22px'}}/>  Asistente virtual</span>
								</>
							} 
						</h3>						
					</div> }>
					
					<div style={{ marginBottom:'20px', padding: '10px', margin: '8px 0px', border: '1px solid lightgrey', borderRadius:'10px', background:'ghostwhite'}}>				
						<b style={{color:'black'}}>Te ayudamos a escribir <span style={{fontSize:'20px'}}>✏️</span></b>
						<div>
							<Input placeholder='Introduce la temática' style={{ height:'25px', color:'black' }} onChange={(e) => { setPhrase(e.target.value) }}/>
						</div>
						<div>
							<ButtonLZ
								disabled={!phrase.trim()}
								onClick={() => {
									getResponseIa();
								}}
								style={{ marginTop:'8px', color: !phrase.trim() ? 'Silver' : 'purple', borderColor: !phrase.trim() ? 'Silver' : 'purple', backgroundColor: 'Lavender', borderRadius: '12px', widht:'40px' }}
								size='small' color='dark-white'>
								{isLoading ? 
									<span style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
										<Spin indicator={<LoadingOutlined spin />} />
									</span> 
									: 
									<div>								
										<b> Redactar</b>
									</div>
								}					
							</ButtonLZ>										
						</div>
					</div>
										
				</Collapse.Panel>
				}
					
			</Collapse>


		</div>
	)
}

export default FormItemSummary;