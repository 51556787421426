import { Button, Card, Divider, Switch, Tag, Tooltip } from "antd"
import { DownOutlined, UpOutlined, CheckCircleFilled } from '@ant-design/icons';
import { useContext, useState } from "react";
import IA from "../../assets/IaFilled.png";
import web from "../../assets/webFilled.png";
import GMB from "../../assets/GMB.png";
import { Badge, Drawer, Skeleton, message } from "../../../node_modules/antd/es/index";
import { useFetch } from "../../hooks/useFetch";
import { ButtonLZ } from "../../components/atoms/ButtonLZ";
import { authFetch } from "../../common/request/authFetch";
import { CheckSquareFilled, InfoCircleOutlined } from "../../../node_modules/@ant-design/icons/lib/icons/index";
import { GlobalVariableContext } from "../../store/GlobalVariableContext/GlobalVariableContext";

export const InfoDrawer = ({onFinish}) => {

    const {setPlansDrawer } = useContext(GlobalVariableContext)
  
    return (
        <Drawer
            closeIcon={null}
            onClose={onFinish}
            placement='right'
            width={'85%'}            
            open={true}
            style={{ background: 'linear-gradient(to bottom, rgba(46, 199, 255, 1), rgba(5, 159, 255, 1))', color: 'white' }}
        >

            <h1>LOCALIZA.ME</h1>            
            <p>Mi <del>Pequeño</del> GRAN negocio</p>
            <br/>            
            <br/>
            <h3>¿Para quien es?</h3>            
            <br/>
            <p>Para negocios con un establecimiento o que ofrecen sus servicios en un área local</p>
            <br/>
            <h3>¿Para que sirve?</h3>            
            <br/>
            <p>Para atraer clientes online y aumentar así la facturación</p>
            <br/>
            <h3>¿Cómo lo consigue?</h3>            
            <br/>
            <p>Sugeririendo acciones de marketing digital que tu mismo podrás aplicar</p>
            <br/>
            <h3>¿Por que?</h3>            
            <br/>
            <p>Es una opción que te permite escalar tu negocio digitalmente de una forma gradual y sencilla</p>
            <br/>
            <h3>¿Que precio tiene?</h3>            
            <br/>
            <p>El paquete base actualmente es <b>GRATUITO</b> e incluye las principales funcionalidades, algunos módulos especiales tienen un sobrecosote mensual:</p>
            <br/><br/>
            <div style={{display: "flex", justifyContent:'flex-end'}}>
                <Button                                            
                    type='link'     
                    onClick={() => setPlansDrawer(true)}                                                                               
                    style={{ color: 'var(--primary)', borderColor: 'var(--primary)', backgroundColor: 'white', marginBottom:'10px' }}>
                        <span><InfoCircleOutlined style={{fontSize:'18px'}}/>  <b>Ver Módulos extra</b></span>
                </Button> 
            </div>
        </Drawer>
    )
}