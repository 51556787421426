import { useTranslation } from 'react-i18next';
import { Period } from './Period';
import { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { DayScheduleEdit, FestiveScheduleEdit } from './FestiveScheduleEdit';
import { DayScheduleView, FestiveScheduleView } from './FestiveScheduleView';
import moment from 'moment';
import { CloseOutlined } from '../../../../../../node_modules/@ant-design/icons/lib/icons/index';

export const FestiveSchedule = ({ date, periods }) => {
    
    const { t } = useTranslation();

    const [edit, setEdit] = useState(false);

    return (
        <div >

            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                <p style={{ color: 'black' }}>
                    {moment(date).format('ll')} 
                </p>
                {edit ?
                    <CloseOutlined style={{color:'grey'}} onClick={() => setEdit(!edit)}/>
                    :
                    <EditOutlined style={{color:'grey'}} onClick={() => setEdit(!edit)}/>
                }
            </div>
            
            <div style={{marginLeft:'15px'}}>
                {!edit ?                     
                    <FestiveScheduleView data={periods}/>                    
                    :
                    <FestiveScheduleEdit 
                        //locationIds={locationIds}
                        festiveDate={date} 
                        ranges={periods} 
                        onCancel={() => {
                            setEdit(false);
                        }}
                        onFinish={() => {
                            setEdit(false);
                        }}/>                        
                }
            </div>
        </div>
    )
}