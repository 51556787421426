import { Form, message } from "antd";
import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import EditableTextAreaItem from "../../../../../components/molecules/Forms/EditableTextAreaItem";
import { authFetch } from "../../../../../common/request/authFetch";
import { GlobalVariableContext } from "../../../../../store/GlobalVariableContext/GlobalVariableContext";

export const EditDescription = ({description, setEdit, edit}) => {

    const {updateGlobalData, setUpdateGlobalData } = useContext(GlobalVariableContext)
    const [descriptionForm] = Form.useForm();
    const { t } = useTranslation();
    const [enabled, setEnabled] = useState('');

    const submitDesc = async(values) => {
        const data = {
			field: 'DESCRIPTION',
			description: values.description
		};

      await authFetch(`${process.env.REACT_APP_API}/app/locations`, { method: 'PATCH', data: data })
      .then((res) => {

        if (res) {
          message.success('La descripción se ha editado correctamente', 3);
          setEdit(false);          
          setUpdateGlobalData(!updateGlobalData);
        } else {
          message.error(t('Error inesperado al editar la descripción'), 3)
        }
      });    
    }

    return (
        <div className="title-box">
            <Form layout='vertical' form={descriptionForm} onFinish={(values) => submitDesc(values)}>
            <EditableTextAreaItem 
                form={descriptionForm}
                value={description}
                field='description'
                label={undefined}                       
                enabled={enabled} 
                setEnabled={setEnabled}
                onSubmitCallback={descriptionForm.submit}
                edit={edit}
                setEdit={setEdit}
                />
          </Form>
        </div>
    )
}

