import { PlusOutlined } from '@ant-design/icons';
import { Form, Upload } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../store/AuthContext/AuthContext';

const FormItemUploadPhoto = ({maxPhotos, label}) => {		

	const { token } = useAuth();
	const [uploadFileList, setUploadFileList] = useState([]);
	const { t } = useTranslation();	

	const onUploadChange = (info) => {

		let fileList = [...info.fileList];		
		fileList = fileList.slice(-1 * maxPhotos);			
		fileList = fileList.map(file => {
		
			if (file.response) {
				file.url = file.response.url;
			}
			return file;
		});
		setUploadFileList(fileList);		
	}

	const uploadProps = {
		action: `${process.env.REACT_APP_API}/app/mediaItems/images`,
		accept: 'image/jpeg,image/png,image/jpg',
		headers: {
			Authorization: 'Bearer ' + token
		},		  
		onChange: onUploadChange,
		multiple: true,		 
		fileList: uploadFileList
	};

	const uploadButton = (
		<div>
		  <PlusOutlined />
		  <div style={{ marginTop: 8 }}>
			Subir foto
		  </div>
		</div>
	  );

  return (		
		<Form.Item label={label} name='photo'>							
			<Upload {...uploadProps} listType='picture-card'>
				{uploadFileList.length >= maxPhotos ? null : uploadButton}						
			</Upload>
		</Form.Item>						
	)
}

export default FormItemUploadPhoto;