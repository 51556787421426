import { Tag } from 'antd'
import { useTranslation } from 'react-i18next';

export const CategoriesView = ({categories}) => {
        
    const { t } = useTranslation();
    
    return (
        <div className="title-box">
            <a>
                <div style={{ margin: "1em 0", display: "flex", justifyContent: "flex-start" }}>
                    <span style={{ color: "#666", cursor:'auto' }}>
                        {categories.primaryCategory.displayName}  <Tag>{'Principal'}</Tag>
                    </span>
                </div>
                {
                    categories.additionalCategories && categories.additionalCategories.map((additionalCategory, i) => (
                        <p style={{ color: "#666", cursor:'auto' }} key={i}>
                            {additionalCategory.displayName}
                        </p>
                    ))
                }
            </a>
        </div>
    )
}