import { useState, createContext } from 'react';

export const GlobalVariableContext = createContext({
    updateGlobalData: false, 
    setUpdateGlobalData:() => {},
    updateHeader: false, 
    setUpdateHeader:() => {},
    plansDrawer: false, 
    setPlansDrawer:() => {},
    infoDrawer: false, 
    setInfoDrawer:() => {}
})

const GlobalVariableProvider = ({ children }) => {

    const [updateGlobalData, setUpdateGlobalData] = useState(false);
    const [updateHeader, setUpdateHeader] = useState(false);
    const [plansDrawer, setPlansDrawer] = useState(false);
    const [infoDrawer, setInfoDrawer] = useState(false);


    return (
        <GlobalVariableContext.Provider value={{updateGlobalData ,setUpdateGlobalData, updateHeader, setUpdateHeader, plansDrawer, setPlansDrawer, infoDrawer, setInfoDrawer }}>
            {children}
        </GlobalVariableContext.Provider>
    );
};

export default GlobalVariableProvider;