import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { CategoriesEdit } from './CategoriesEdit';
import { CategoriesView } from './CategoriesView';

export const Categories = ({location}) => {

    const [edit, setEdit] = useState(false);
    const [categories, setCategories] = useState(location.categories);
    
    const { t } = useTranslation();

    const handleCancel = () => {
        setCategories(location.categories);
        setEdit(false);
    }

    const handleSuccess = (data) => {
        setCategories(location.categories);
        setEdit(false);
    }

    return (
        <div>
            <span  onClick={() => setEdit(true)}>
                <b className="title-box">Categoría <EditOutlined /></b>
            </span>
          
            {edit?                  
                <CategoriesEdit 
                        locationId = {location?.name}
                        primaryCategory = {categories.primaryCategory} 
                        additionalCategories = {categories.additionalCategories} 
                        handleSuccess = {handleSuccess}
                        handleCancel = {handleCancel} />        
                :
                <CategoriesView categories={location.categories}/>
            }
        </div>
    )
}