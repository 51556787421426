import { Alert, Card, Collapse, Divider, Form, Input, Switch, Tooltip } from "antd"
import "../../styles/WebPage.css"
import { useContext, useEffect, useRef, useState } from "react";
import { authFetch } from "../../common/request/authFetch";
import { useAuth } from "../../store/AuthContext/AuthContext";
import { useFetch } from "../../hooks/useFetch";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { WEBCONFIG_NAME, WEBCONFIG_TYPE, WEBCONFIG_WARNING } from "../../common/constants/webConfig";
import { Badge, Button, Skeleton, Spin } from "../../../node_modules/antd/es/index";
import { GlobalVariableContext } from "../../store/GlobalVariableContext/GlobalVariableContext";
import { useIsAuthorized } from "../../common/constants/AuthUtils";
import { CaretRightOutlined, ControlOutlined, GlobalOutlined, LockFilled, PlusCircleOutlined, SettingFilled, SettingOutlined, SettingTwoTone } from "../../../node_modules/@ant-design/icons/lib/icons/index";
import { WebPreview } from "./modal/WebPreview";
import { ButtonLZ } from "../../components/atoms/ButtonLZ";

export const WebPage = () => {
    
    const formRef = useRef(null);
    const [updateData, setUpdateData] = useState(false);
    const {setPlansDrawer} = useContext(GlobalVariableContext);
    const isAuthorized = useIsAuthorized();
    const [videoDrawerOpen, setVideoDrawerOpen] = useState(true);

    const [edit, setEdit] = useState(null);
    const [text, setText] = useState(null);
    const [webColor, setWebColor] = useState(null);
    const [webPreviewDrawer, setWebPreviewDrawer] = useState(false);

    const { data, isLoading } = useFetch(
        `${process.env.REACT_APP_API}/app/web/config`,
        {},
        [updateData],
    );

    useEffect(() => {
        setWebColor(data?.color);        
    }, [data])

    const closeForm = () => {
        setEdit(null)
        setText(null)
    };

    const submitForm = async (value) => {        
        saveConfiguration(value, true, text)
    };

    const saveConfiguration = async (key, value, text) => {
        
        const body = {
            active: value,
            text,
            key
        };

        await authFetch(`${process.env.REACT_APP_API}/app/web/config`, { method: 'POST', data: body }).then((res) => {
            setUpdateData(!updateData);
            closeForm();
        });
    }

    const saveColor = async (value) => {

        const encodedColor = encodeURIComponent(value);
        await authFetch(`${process.env.REACT_APP_API}/app/web/config/color/${encodedColor}`, { method: 'POST' }).then((res) => {
            setUpdateData(!updateData);
        });
    }

    const handleSwitchChange = (config, checked) => {
        saveConfiguration(config.key, checked, config.text)
    };

    return (
        isLoading ?        
        <div style={{ display: 'flex', flexDirection: 'column', width:'100%' }}>
            <br/>
            <Spin/>            
            <br/>
            <Skeleton active/>
            <br/><br/>
            <Skeleton active/>            
        </div>
        :        
        <div style={{margin:'0px 15px'}}>                    

    {isAuthorized(['ROLE_APP_WEB']) ?
            <>
                {data?.webActive ? 
                <Card style={{ marginBottom:'10px', borderBottom: `5px solid green` }}>
                    <Badge status="success" text="Web Activa" /> 
                    </Card>
                    : 
                    <Card style={{ marginBottom:'10px', borderBottom: `5px solid orange` }}>
                    <Badge status="warning" text="Asignando dominio" />
                    </Card>
                } 
            </>            
            :     
            <Card style={{ marginBottom:'10px', borderBottom: `5px solid ${data?.color}` }}>
                <h2>                
                    ¿Aún no tienes web?
                </h2>
                <p>Estamos aquí para ayudarte</p>
                <div style={{ display: 'flex', justifyContent: 'center', width:'100%' }}>
                    <ButtonLZ size='large' onClick={() => setWebPreviewDrawer(true)} style={{ marginTop:'5px', backgroundColor: 'white', color: 'dodgerblue', borderColor: 'dodgerBlue' }}>
                        <GlobalOutlined/><b> Previsualizar WEB</b>
                    </ButtonLZ>                                      
                </div>
            </Card>
        }

            {<h3 style={{color:'var(--primary)', marginTop:'15px'}}>Configura tu web desde aquí <span style={{fontSize:'30px'}}>🥷🏻</span></h3>}
            {<Card style={{ border: `2px solid ${data?.color}`, marginTop:'15px' }}>                    
                <div>
                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <h3><SettingOutlined/> Color</h3>                        
                        <input
                            value={webColor}
                            type='color'
                            style={{ width: '30px', height: '30px', marginTop: '5px', paddingTop: 0 }}
                            onChange={(e) => setWebColor(e.target.value)}
                            onBlur={(e) => { saveColor(e.target.value) }} />                        
                    </div>
                    <Divider/>
                    <h3 style={{marginBottom:'15px'}}><SettingOutlined/> Bloques Visibles</h3>                        


                    <ul className="configuration">

                        {data?.blocks?.map((config, index) => {

                            return (
                                <li key={index}>
                                    <div style={{marginLeft:'20px'}}>
                                        <div>
                                            {WEBCONFIG_NAME[config.key]}
                                        </div>

                                        {config.type !== WEBCONFIG_TYPE.NON_CONFIGURABLE && (
                                            <div>
                                                {(config.type == WEBCONFIG_TYPE.CONFIGURABLE_WITH_TEXT && config.active && edit !== config.key) &&
                                                    <Button                                            
                                                        type='link'  
                                                        size='small'   
                                                        style={{color:`${config?.text ? 'green' : 'blue'} `}}
                                                        onClick={()=>{setEdit(config.key)}} >                                                  
                                                            Texto
                                                    </Button> 
                                               }
                                                <Switch                                                    
                                                    size="small"
                                                    checked={config.active}
                                                    onChange={(checked) => handleSwitchChange(config, checked)}
                                                />
                                            </div>
                                        )}
                                    </div>

                                    {(edit === config.key) && (
                                        <Card style={{marginTop:'10px', width:'100%',textAlign:'center', background:'ghostwhite'}}
                                            bodyStyle={{margin:'8px', padding:'8px', width:'100%'}}>
                                            <Form
                                                layout='vertical'
                                                form={formRef.current}
                                                key={config.key}
                                                onFinish={(values) => submitForm(config.key, values)}
                                                initialValues={{ text: config.text }}>
                                                    
                                                <Form.Item name='text'>
                                                    <Input.TextArea 
                                                        style={{width:'100%'}}
                                                        placeholder='Escriba el texto que se mostrará en la web.'
                                                        onBlur={(e) => { setText(e.target.value) }}
                                                        rows={6}
                                                        autoFocus={true} />
                                                </Form.Item>
                                                <a className='review-btn' onClick={closeForm}>
                                                    <CloseOutlined /> Cancelar
                                                </a>
                                                <a className='review-btn' onClick={() => submitForm(config.key)}>
                                                    <CheckOutlined /> Guardar
                                                </a>
                                            </Form>
                                        </Card>
                                    )}
                                </li>
                            );
                        })}
                    </ul>

                </div>                
                </Card>
            }
            {!isAuthorized(['ROLE_APP_WEB']) && 
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button                                            
                        type='link'     
                        onClick={() => setPlansDrawer(true)}                                                                               
                        style={{ color: 'blue', borderColor: 'blue', backgroundColor: 'Aliceblue', margin:'10px', width:'100%', minHeight:'50px' }}>
                            <span><LockFilled style={{fontSize:'18px'}}/>  <b>Activar Web</b></span>
                    </Button>                             
                </div>  
        }
        {webPreviewDrawer && <WebPreview
                                webId={data.webId}
                                onFinish={() => {
                                    setWebPreviewDrawer(false);
                                }}/>}
        </div>
    )
}