import { Divider, Tooltip } from "antd";
import React from "react";

export default function ReviewComment(props) {

  return (
    <div className='comment-review'>
      { 
        props.comment?.originalText ? props.comment?.originalText : props.comment?.text
      }
      {/* {
        props.comment?.translatedText &&
          <>
            <Divider style={{margin: '10px 0px'}}/>
            <small style={{color:'#bbb'}}>            
              <i><b>Translated By Google:</b> {props.comment?.translatedText}</i>
            </small>
          </>
      } */}
    </div>
  );
}
