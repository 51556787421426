import { Button, Card, Progress } from "antd";
import { Link } from 'react-router-dom';
import { useFetch } from "../../hooks/useFetch";
import { useAuth } from "../../store/AuthContext/AuthContext";
import { StarFilled, NotificationFilled, GlobalOutlined } from "@ant-design/icons"
import "../../styles/Home.css"
import { Badge, Radio, Rate, Select, Skeleton, Spin } from "../../../node_modules/antd/es/index";
import { DoubleRightOutlined, PlusCircleOutlined, PlusCircleTwoTone, RightCircleOutlined, ShopFilled } from "../../../node_modules/@ant-design/icons/lib/icons/index";
import { useState } from "react";
// import { TinyArea } from '@ant-design/plots';

const badgeColor = {
    "success": "success",
    "warning": "warning",
    "critical": "error"
}

const colorAlert = {
    "success": "OliveDrab",
    "warning": "DarkOrange",
    "critical": "Tomato"
}

const colorAlertBackground = {
    "success": "MintCream",
    "warning": "SeaShell",
    "critical": "LavenderBlush"
}

const emojiInfo = {
    "success": '😎',
    "warning": '🫣',
    "critical": '😵‍💫'
}

const emojiReputation = {
    "success": '🤩',
    "warning": '🥺',
    "critical": '😬'
}

const emojiVisibility = {
    "success": '😜',
    "warning": '😵',
    "critical": '😩'
}

const emojiConversion = {
    "success": '🥳',
    "warning": '😰',
    "critical": '😱'
}

export const Home = () => {

    const [datesFilter, setDatesFilter] = useState('3m');
    const {data, isLoading} = useFetch(`${process.env.REACT_APP_API}/app/audit?datesFilter=${datesFilter}`, {}, [])
    const { profile } = useAuth();

    return (
     <div> 
        <div style={{margin:'0px 20px 10px 20px', display: 'flex', justifyContent:'space-between', alignItems:'center'}}>
            <h2 style={{color:'var(--primary)'}}>Hola {profile?.name} <span style={{fontSize:'30px'}}>👋</span></h2>
        </div>
        <div style={{margin:'0px 20px 15px 20px', display: 'flex', justifyContent:'flex-end', alignItems:'center'}}>
            <small style={{color:'MidnightBlue'}}>Últimos</small>
            <Select defaultValue='3m' size='small' onChange={(value) => setDatesFilter(value)} style={{ minWidth: '100px' }}>															
                <Select.Option value='2y'>
                    <small style={{color:'MidnightBlue'}}>2 años</small>
                </Select.Option>
                <Select.Option value='1y'>
                    <small style={{color:'MidnightBlue'}}>1 año</small>
                </Select.Option>
                <Select.Option value='3m'>
                    <small style={{color:'MidnightBlue'}}>3 meses</small>
                </Select.Option>
            </Select>
        </div>        
 
     <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', margin:'0px 25px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            
            <Card className="box-kpis" bodyStyle={{padding:'17px'}}> 
                <div className="header-kpis">
                    <h1><Badge status={badgeColor[data?.infoKpi?.severity]} size='large'/>   MI NEGOCIO</h1>       
                    {/*<DoubleRightOutlined/>*/}
                </div>
                <div className="content-kpis">
                   {!isLoading &&<Progress percent={Math.round(data?.infoKpi?.metric*100)} size={45} type="circle" strokeColor={colorAlert[data?.infoKpi?.severity]} />}
                   {isLoading && <Spin/>}
                </div>
                <div className="footer-kpis">
                    Info en Google
                </div>
            </Card>

            <Card className="box-kpis" bodyStyle={{padding:'17px'}}> 
                <div className="header-kpis">
                    <h1><Badge status={badgeColor[data?.reviewsKpi?.severity]}/>   REPUTACIÓN</h1>
                    {/*<DoubleRightOutlined/>*/}
                </div>
                <div className="content-kpis">
                {isLoading ?                    
                        <Spin/>
                        :
                        data?.reviewsKpi?.totalReviews > 0 ?
                        <div style={{display:'flex', alignItems:'center', justifyContent: 'center', marginTop: '5px'}}>
                            <h2>{data?.reviewsKpi?.avgScore?.toPrecision(1)}</h2>
                            <span style={{color:'orange', fontSize:'24px'}}><StarFilled/></span>                            
                        </div>
                        :
                        <h2>-</h2>}               
                </div>
                <div className="footer-kpis">
                    {data?.reviewsKpi?.totalReviews > 0 ? 
                        <>{data?.reviewsKpi?.totalReviews} reseñas</>
                        :
                        <p>No hay reseñas</p>}               
                </div>
            </Card>            
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Card className="box-kpis" bodyStyle={{padding:'17px'}}> 
                <div className="header-kpis">
                    <h1><Badge status={badgeColor[data?.visibilityKpi?.severity]} size='large'/>   VISIBILIDAD</h1>
                    {/*<DoubleRightOutlined/>*/}
                </div>
                <div className="content-kpis">
                {!isLoading &&<div style={{display:'flex', alignItems:'baseline', gap:'10px'}}>                        
                        <div style={{ lineHeight: '20px', display:'flex', alignItems:'center', flexDirection:'column'}}>  
                            <h2>{Math.round(data?.visibilityKpi?.impressionsPerMonth)}</h2>                        
                            <b style={{fontSize:'10px'}}>impresiones/mes</b>
                        </div> 
                    </div>}
                    {isLoading && <Spin/>}
                </div>
                <div className="footer-kpis">
                    {data?.visibilityKpi?.impressions} impresiones
                </div>
            </Card>
            <Card className="box-kpis" bodyStyle={{padding:'17px'}}> 
                <div className="header-kpis">
                    <h1><Badge status={badgeColor[data?.conversionsKpi?.severity]} size='large'/>   CLIENTES</h1>
                    {/*<DoubleRightOutlined/>*/}
                </div>
                <div className="content-kpis">
                {isLoading ?
                    <Spin/>
                    :
                    <div style={{ lineHeight: '20px', display:'flex', alignItems:'center', flexDirection:'column'}}>  
                        <h2>{Math.round(data?.conversionsKpi?.directionsPerMonth)}</h2>
                        <b style={{fontSize:'10px'}}>acciones/mes</b>
                    </div>
                }
                </div>
                <div className="footer-kpis">
                    {data?.conversionsKpi?.directions} acciones
                </div>
            </Card>
        </div>
    </div>
    <div style={{margin:'10px'}}>
        <div style={{margin:'30px 20px 15px 20px'}}>
            <h3 style={{color:'var(--primary)'}}>¿Quieres atraer más clientes? <span style={{fontSize:'30px'}}>🎯</span></h3>
            {/*<p style={{color:'grey'}}>Sigue estas sugerencias de forma regular y poco a poco verás el impacto</p>*/}
        </div>
        { !isLoading?
        <>
        {data?.infoAlert?.length > 0 &&
        <Card style={{border: '2px solid', borderRadius:'30px', backgroundColor: colorAlertBackground[data?.infoAlert[0]?.severity], borderColor:colorAlert[data?.infoAlert[0]?.severity], display:'flex', marginBottom:'10px'}} bodyStyle={{padding:'20px 15px',  width:'100%'}}>
            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', gap:'10px'}}>
                <div style={{backgroundColor:colorAlertBackground[data?.infoAlert[0]?.severity], width:'25px', height:'25px', padding:'15px', borderRadius:'25px', alignItems:'center', display:'flex', justifyContent:'center'}}>
                <span style={{fontSize:'30px'}}>{emojiInfo[data?.infoAlert[0]?.severity]}</span>
            </div>
            <p style={{color:'black', width:'100%', fontSize:'13px'}}>{data?.infoAlert[0]?.description}</p>
            <Link to={data?.infoAlert[0]?.link}>
            <Button shape='round' style={{border:'MidnightBlue 1px solid', color:'MidnightBlue', width:'130px'}}><ShopFilled/><b style={{fontSize:'12px'}}> Completa</b></Button>
            </Link>
            </div>
        </Card>}

        {data?.webAlert &&
        <Card style={{border: '2px solid', borderRadius:'30px', backgroundColor: colorAlertBackground[data?.webAlert?.severity], borderColor:colorAlert[data?.webAlert?.severity], display:'flex', marginBottom:'10px'}} bodyStyle={{padding:'20px 15px',  width:'100%'}}>
            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', gap:'10px'}}>
                <div style={{backgroundColor:colorAlertBackground[data?.webAlert?.severity], width:'25px', height:'25px', padding:'15px', borderRadius:'25px', alignItems:'center', display:'flex', justifyContent:'center'}}>
                <span style={{fontSize:'30px'}}>{emojiVisibility[data?.webAlert?.severity]}</span>
            </div>
            <p style={{color:'black', width:'100%', fontSize:'13px'}}>{data?.webAlert?.description}</p>
            <Link to={data?.webAlert?.link}>
            <Button shape='round' style={{border:`MidnightBlue 1px solid`, color:'MidnightBlue', width:'130px'}}><GlobalOutlined/><b style={{fontSize:'12px'}}> Activa</b></Button>
            </Link>
            </div>
        </Card>}

        {data?.reviewsAlert &&
        <Card style={{border: '2px solid', borderRadius:'30px', backgroundColor:colorAlertBackground[data?.reviewsAlert?.severity], borderColor: colorAlert[data?.reviewsAlert?.severity], display:'flex', marginBottom:'10px'}} bodyStyle={{padding:'20px 15px',  width:'100%'}}>
            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', gap:'10px'}}>
                <div style={{backgroundColor:colorAlertBackground[data?.reviewsAlert?.severity], width:'25px', height:'25px', padding:'15px', borderRadius:'25px', alignItems:'center', display:'flex', justifyContent:'center'}}>
                <span style={{fontSize:'30px'}}>{emojiReputation[data?.reviewsAlert?.severity]}</span>
            </div>
            <p style={{color:'black', width:'100%', fontSize:'13px'}}>{data?.reviewsAlert?.description}</p>
            <Link to={data?.reviewsAlert?.link}>
            <Button shape='round' style={{border:'MidnightBlue 1px solid', color:'MidnightBlue', width:'130px'}}><StarFilled/><b style={{fontSize:'12px'}}> Responde</b></Button>
            </Link>
            </div>
        </Card>}

        {data?.visibilityAlert &&
        <Card style={{border: '2px solid', borderRadius:'30px', backgroundColor: colorAlertBackground[data?.visibilityAlert?.severity], borderColor: colorAlert[data?.visibilityAlert?.severity], display:'flex', marginBottom:'10px'}} bodyStyle={{padding:'20px 15px',  width:'100%'}}>
            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', gap:'10px'}}>
                <div style={{backgroundColor:colorAlertBackground[data?.visibilityAlert?.severity], width:'25px', height:'25px', padding:'15px', borderRadius:'25px', alignItems:'center', display:'flex', justifyContent:'center'}}>
                <span style={{fontSize:'30px'}}>{emojiConversion[data?.visibilityAlert?.severity]}</span>
            </div>
            <p style={{color:'black', width:'100%', fontSize:'13px'}}>{data?.visibilityAlert?.description}</p>
            <Link to={data?.visibilityAlert?.link}>
            <Button shape='round' style={{border:'MidnightBlue 1px solid', color:'MidnightBlue', width:'130px'}}><NotificationFilled/><b style={{fontSize:'12px'}}> Publica</b></Button>
            </Link>
            </div>
        </Card> }
        </>
        :
        <Skeleton/>
        }
    </div>
        
    </div> )

}

