import { useEffect, useState } from "react";
import { AutoComplete } from "antd";
import { getProjectStorage } from "../../../../../store/storageContext";
import { useFetch } from "../../../../../hooks/useFetch";
import { useAuth } from "../../../../../store/AuthContext/AuthContext";

const InputCategories = ({ fieldId, onFocus, placeholder, onChange, defaultValue, disabled }) => {

  const [categories, setCategories] = useState([]);
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  useFetch(`${process.env.REACT_APP_API}` + '/app/locations/categories', {}, [], (data) => {
    setCategories(data.categories);

  });

  const [opciones, setOpciones] = useState([]);

  const handleBuscarCategorias = (value) => {
    const categoriasFiltradas = categories.filter((categoria) =>
      categoria.displayName.toLowerCase().startsWith(value.toLowerCase())
    );
    setOpciones(categoriasFiltradas.map((categoria) => categoria.displayName));
  };


  const handleChange = (value) => {

    const selectedCategory = categories.find(
      (categoria) => categoria.displayName === value
    );

    onChange(selectedCategory || undefined);

    setSelectedValue(value);
  };

  useEffect(() => {
    if (defaultValue) {
      setSelectedValue(defaultValue.displayName);
    }
  }, [defaultValue]);

  return (
    <AutoComplete
      placeholder={placeholder}
      disabled = {disabled}
      id={fieldId}
      onFocus={onFocus}
      options={opciones.map((opcion) => ({ value: opcion }))}
      onSearch={handleBuscarCategorias}
      value={selectedValue}
      onSelect={(value) => handleChange(value)}
      onChange={(value) => setSelectedValue(value)}
    />
  );
};

export default InputCategories;
