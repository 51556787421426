import { EditOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { InfoView } from '../InfoView';
import { EditName } from './EditName';

export const Name = ({location}) => {

    const [edit, setEdit] = useState(false);

    const { t } = useTranslation();

    return (
        <div>        
            <span  onClick={() => setEdit(true)}>
                <b className="title-box">Nombre <EditOutlined /></b>
            </span>
            {
                edit ? 
                    <EditName 
                        name={location?.title} 
                        locationId={location?.name} 
                        setEdit={setEdit} 
                        edit={edit} /> 
                :                 
                    <InfoView data={location?.title}/>
            }                        
        </div>
    )
}
