import { Layout as AntdLayout, FloatButton } from 'antd';
import { CommentOutlined, CloseOutlined } from '@ant-design/icons';
import { useState } from 'react';
import ChatBot from '../components/organism/ChatBot';
import { useAuth } from '../store/AuthContext/AuthContext';
import { useLocation } from 'react-router-dom';
import { AppRoutes } from '../routing/AppRoutes';
import "../styles/MainContent.css"

const { Content } = AntdLayout;

const MainContent = () => {

	/*const [chatbotVisible, setChatbotVisible] = useState(false);

	const toggleChatbot = () => {
		setChatbotVisible(!chatbotVisible);
	};*/

	const { isAuth } = useAuth();
	const location = useLocation();

	return (<>
		<Content className='main-content'
			style={{
				//padding: '0px 10px 100px 10px',
				//minHeight:'100vh',
				//background: '#b6e7ff',
				...(location.pathname === '/login' && {
					background: 'transparent',
				}),
			}}
		>
			
			<AppRoutes />			
			{/* {isAuth && <FloatButton
				icon={chatbotVisible ? <CloseOutlined /> : <CommentOutlined />}
				style={{ bottom: '80px' }}
				onClick={toggleChatbot}
			/>}
			 */}
			{/* <ChatBot chatbotVisible={chatbotVisible}/> */}


		</Content>
	</>
	)
};

export default MainContent;