import { Card } from "antd";
import { useFetch } from "../../hooks/useFetch";

export const Notifications = () => {


    const { data: storesData } = useFetch(`${process.env.REACT_APP_API}/users/notifications/`, {}, []);

    return (
        <Card className='main-card-content'>
            <h2 className='title-card'>Notificaciones</h2>


            <div style={{ padding: '10px 20px' }}>
                <ul>
                    {storesData?.notificationList?.map((n) => {
                        return <li><div><h3>{n.title}</h3>{n.content}</div></li>
                    })}
                </ul>
            </div>
        </Card>
    )
}