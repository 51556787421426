import { t } from 'i18next';
import moment from 'moment';

import { DaySpecialSchedule, FestiveSchedule } from './components/FestiveSchedule';

export const SpecialSchedule = ({ location }) => {

    return (
        <div>            
            <span>
                <b className="title-box">Festivos</b>
            </span>

            <div style={{ margin: "1em" }}>
                
                {location.specialHours && Object.entries(location.specialHours).map(([date, periods]) => {
                    return (
                        <div key={date}>
                            <div style={{ display: "flex", flexDirection: 'column', justifyContent: "flex-start", marginTop:'10px' }}>                                                                        
                                <FestiveSchedule date={date} periods={periods}/>                                                                            
                            </div>
                        </div>
                    );
                })}
            </div>               
        </div >
    );
};