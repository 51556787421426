import { MinusCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import { Form, Input, TimePicker } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import moment from 'moment';
import { SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Period } from './Period';

export const FestiveScheduleView = ({
	data
}) => {
	
	const { t } = useTranslation();

	return (
		<div>
			{data[0]?.closed ? 
                        <b style={{color:'LightSalmon'}}>Cerrado</b>
                        :
                        data.map((period, i) => (                    
                            <Period data={period} key={i} />
                        ))
			}
		</div>
	)
}
