import { Button, Form, Input, Modal, message } from "antd";
import { useAuth } from "../../../../../store/AuthContext/AuthContext";
import { useTranslation } from "react-i18next";
import { authFetch } from "../../../../../common/request/authFetch";
import { getProjectStorage } from "../../../../../store/storageContext";
import { useContext, useState } from "react";
import { GlobalVariableContext } from "../../../../../store/GlobalVariableContext/GlobalVariableContext";

export const EditPhone = ({ primaryPhone, addPhones, setEdit }) => {

    const {updateGlobalData, setUpdateGlobalData } = useContext(GlobalVariableContext);
    const [phoneForm] = Form.useForm();
    const { t } = useTranslation();

    const phones = { mainPhone: primaryPhone.replace(/\s+/g, ""), additionalPhones: addPhones };
    const additionalPhones = [0, 0];

    const fieldId = 'phoneID';
	const [enabled, setEnabled] = useState('');


    const submitPhone = async (values) => {
        const data = {
            field: 'PHONES',
            phones: {
                mainPhone: values.mainPhone,
                additionalPhones: values.additionalPhones,
            }
        };

        await authFetch(`${process.env.REACT_APP_API}/app/locations`, { method: 'PATCH', data: data })
            .then((res) => {

                if (res) {
                  message.success('El teléfono se ha editado correctamente', 3);
                    setEdit(false);
                    setUpdateGlobalData(!updateGlobalData);
                } else {
                  message.error(t('Error inesperado al editar el teléfono'), 3)
                }
            });

    }

    return (
        <div className="title-box">
            <Form layout='vertical' form={phoneForm} onFinish={(values) => submitPhone(values)} initialValues={phones} >
            <Form.Item name='mainPhone'
              style={{ width: '80%' }}
              rules={[{ pattern: new RegExp(/^[0-9]+$/), message: t('view.forms.fields.phone.rule')  }]}>
              <Input id={fieldId}
              onChange={(e) => { setEnabled(e.target.value !== '' ? e.target.id : '') }}
              onFocus={(e) => { setEnabled(e.target.value !== '' ? e.target.id : '') }} />
            </Form.Item>

            <Form.List name="additionalPhones">
              {(fields, { add, remove }) => {
                return (
                  <>
                    {
                      fields.map((field, index) => (
                        <div key={field.key}>
                          {
                            <div style={{ display: 'flex' }}>
                              <Form.Item name={[index]}
                                style={{ width: '80%' }}
                                rules={[{ pattern: new RegExp(/^[0-9]+$/), message: t('view.forms.fields.phone.rule')  }] }>
                                <Input />
                              </Form.Item>
                              {
                                fields.length > 0 && <button
                                  style={{ width: '20%', height: '32px', backgroundColor: "transparent", border: "none", fontWeight: 600, padding: "0 1em", cursor: "pointer" }}
                                  onClick={() => remove(field.name)} >
                                  X
                                </button>
                              }
                            </div>
                          }
                        </div>
                      ))
                    }
                    {(fields.length < 2) &&
                      <button type='button' style={{ paddingBottom: "1em", border: "none", backgroundColor: "transparent", color: "#2781DD", fontWeight: 600, cursor: "pointer" }} onClick={() => add()}>
                       + Añadir teléfono
                      </button>}
                  </>)
              }}
            </Form.List>
            <div><Button
                  disabled={!!phoneForm.getFieldsError().filter(({ errors }) => errors.length).length || enabled !== fieldId}
                  onClick={() => {
                    Modal.confirm({
                      title: t('view.forms.modal.bulk.title'),
                      content: t('view.forms.modal.bulk.content', { field: t('dictionary.phone').toLowerCase() }),
                      okText: t('dictionary.confirm'),
                      cancelText: t('dictionary.cancel'),
                      onOk: phoneForm.submit
                    })
                  }}
                  type="primary">
                  {t('dictionary.save')}
                </Button>
                  <Button
                    style={{ background: 'white', color: 'dodgerblue', marginLeft: '20px' }}
                    onClick={() => { 
                      setEdit(false) }}
                    type="primary">
                    { t('dictionary.cancel') }
                  </Button>
                </div>
                </Form>
        </div >
    )
}
