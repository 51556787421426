import React from "react";
import { Result } from "../../../../node_modules/antd/es/index";


export const Error403 = () => {

  return (
    <Result
    status="403"
    title="403"
    subTitle={'Falta de permisos'}    
  />)
}
