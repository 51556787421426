import { useState } from "react";
import { useFetch } from "../../hooks/useFetch";
import "../../styles/ReviewList.css";
import { ReviewDetailDrawer } from "./Drawer/ReviewDetailDrawer";
import { ReviewsPaginated } from "./ReviewsPaginated";
import { Card, Radio, Switch } from "../../../node_modules/antd/es/index";
import reviewsImg from '../../assets/reseñas-empty-component.png'

export const ReviewList = () => {

  const [allFilter, setAllFilter] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);

  const { data, isLoading } = useFetch(
    `${process.env.REACT_APP_API}/app/reviews?allFilter=${allFilter}`,
    {},
    [allFilter, updateData],
  );

  return (
    <div style={{margin:'20px'}}>           
      
      <h3 style={{color:'var(--primary)'}}>Contesta de forma educada <span style={{fontSize:'30px'}}>😉</span></h3>

      <div style={{display:'flex', justifyContent: "center", margin:'20px 0px'}}>
        {/*<Switch style={{margin:'15px 0px'}} checked={allFilter} checkedChildren='Todas' unCheckedChildren='Pendientes' onChange={(checked) => setAllFilter(!allFilter)} />*/}
        <Radio.Group value={allFilter}  buttonStyle="solid" onChange={(checked) => setAllFilter(!allFilter)}>
          <Radio.Button value={true}>Todas</Radio.Button>
          <Radio.Button value={false}>Sin Contestar</Radio.Button>        
        </Radio.Group>
      </div> 

      {data?.length > 0 && 
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>        
          <ReviewsPaginated setSelectedReview={setSelectedReview} data={data}/>
        </div>
      }
      
      {data?.length == 0 && 
        <Card style={{textAlign:'center', background:'ghostwhite'}}>
          <h3 style={{color:'lightgrey'}}>{allFilter ? <>No hay reseñas</> : <>No hay reseñas sin contestar</>}</h3>
          <div style={{display:'flex', flexDirection:'column'}}>
            <img src={reviewsImg} />
          </div>
        </Card>
      }

      {selectedReview && 
        <ReviewDetailDrawer 
          onFinish={() => { setSelectedReview(null); 
                            setUpdateData(!updateData);
                          }} 
          selectedReview={selectedReview.name}/>}

    </div>)
}