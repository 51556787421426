import { Form, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FormItemPublishPhotoType = () => {		

  const { t } = useTranslation();	

  return (		
	<Form.Item initialValue={''} label='Tipo' name='mediaType' rules={[{ required: true}]}>
		<Select>															

			<Select.Option value='INTERIOR'>
				Interior
			</Select.Option>
			<Select.Option value='ADDITIONAL'>
				Adicional
			</Select.Option>
			<Select.Option value='AT_WORK'>
				En el trabajo
			</Select.Option>
			{/*<Select.Option value='FOOD_AND_DRINK'>
				Comida y bebida
				</Select.Option>
			<Select.Option value='EXTERIOR'>
				Exterior
			</Select.Option>
				<Select.Option value='PRODUCT'>
					Producto
				</Select.Option>
			<Select.Option value='MENU'>
				Menú
			</Select.Option>
			<Select.Option value='COMMON_AREA'>
				Área común
			</Select.Option>
			<Select.Option value='ROOMS'>
				Habitaciones
			</Select.Option>
			<Select.Option value='TEAMS'>
				Equipos
  			</Select.Option>*/}

		</Select>
	</Form.Item>				
	)
}

export default FormItemPublishPhotoType;