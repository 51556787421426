import { Button, Form, message, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../store/AuthContext/AuthContext';
import { authFetch } from '../../../../common/request/authFetch';
import { getProjectStorage } from '../../../../store/storageContext';
import { useState } from 'react';
import FormItemPublishPhotoType from '../../../../components/molecules/Forms/FormItemPublishPhotoType';
import FormItemUploadPhoto from '../../../../components/molecules/Forms/FormItemUploadPhoto';

export const PostImages = ({
    location,
    visible,
    onCancel,
    onFinish,
}) => {

    const [form4] = Form.useForm();
    const { t } = useTranslation();

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const handleFormChange = () => {
        const values = form4.getFieldsValue();
        const { mediaType, photo } = values;
            if (mediaType && photo?.fileList?.length > 0) {
          setIsButtonDisabled(false);
        } else {
          setIsButtonDisabled(true);
        }
    };

    const submitMedia = async (values) => {

        const url = values.photo.fileList[0].url;

        const data = {
            category: values.mediaType,
            photoURL: url,
            locationsNames: location
        };
        massivePublish(data, 'localPostMedia');
    };

    const massivePublish = async (data, type) => {
        await authFetch(`${process.env.REACT_APP_API}/app/mediaItems/` + type, { method: 'POST', data: data })
            .then((res) => {
                if (res) {
                    message.loading('La imagen se ha publicado', 5);
                } else {
                    message.error('Error, asegurate que el tipo es compatible con tiu negocio', 5);
                }
            });
        onFinish();
    }

    return (
        <Modal visible={visible} onCancel={onCancel} footer={null} >

            <h2 style={{ paddingTop: '5px', margin:0, paddingRight: '15px' }}>Publicación de fotos</h2>

            <Form layout='vertical' form={form4} onFinish={submitMedia} style={{marginTop:'10px'}}  onValuesChange={handleFormChange}
>               <FormItemPublishPhotoType />
                <FormItemUploadPhoto maxPhotos={1} />
                <div style={{display:'flex', justifyContent:'flex-end'}} >
                    <Button shape='round' type='primary' onClick={form4.submit} disabled={isButtonDisabled}>
                        Publicar foto
                    </Button>
                </div>
            </Form>

        </Modal>
    )
}
