import { useState } from 'react';
import { authRequest } from '../../common/request/authRequest';
import { Card } from 'antd';
import "../../styles/ChatBot.css"
import { useFetch } from '../../hooks/useFetch';
import { SendOutlined, HomeOutlined, MessageOutlined } from '@ant-design/icons';
import { useRef } from 'react';
import { useEffect } from 'react';

function ChatBot({ chatbotVisible }) {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');


  const conversationRef = useRef(null);


  const [selectedTab, setSelectedTab] = useState('home');
  const [update, setUpdate] = useState(false);

  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };

  const fetchReply = async (message) => {

    await authRequest(`${process.env.REACT_APP_API}/projects/DeepCoding/chatBot?message=${message}`, { method: 'POST' }).then((res) => {
      setMessages((prevMessages) => [...prevMessages, { text: res, sender: 'assistant' }]);
      setUpdate(!update)
    });
  }

  
  const handleSend = () => {
    setMessages((prevMessages) => [...prevMessages, { text: input, sender: 'user' }]);
    fetchReply(input);
    setInput('');
  };


  const { data } = useFetch(`${process.env.REACT_APP_API}/projects/DeepCoding/chatBot/conversation`, {}, [update]);

  const scrollToBottom = () => {
    if (conversationRef.current) {
      conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
    }
  };

  useEffect(()=>{

  scrollToBottom()
  },[data])

  return (
    <Card
      className={`chatbot-container ${chatbotVisible ? 'open' : ''}`}
      style={{
        height: '600px', width: '350px', position: 'fixed', bottom: '130px', right: '20px', boxShadow: "0px 0px 20px -10px"
      }}>


      <div style={{ minHeight: '55vh' }}>
        {selectedTab === 'home' ? (
          <div className="home-content">
          </div>
        ) : (
          <div className="chat-content">
            <div style={{ maxHeight: '50vh', overflowY: 'auto', display: 'flex', flexDirection: 'column', gap: '10px' }} ref={conversationRef}>
              {data.map((chat) => {
                return (
                  chat.content.length > 0 && <div className={`${chat.role != 'user' ? 'response' : 'user'}`}>
                    <p>{chat.content}</p>
                  </div>
                )
              })}
            </div>

            <div className='input' >
              <input value={input} onChange={e => setInput(e.target.value)} />
              <button onClick={handleSend}><SendOutlined /></button>
            </div>

          </div>
        )}
      </div>


      <div className="footer">
        <a
          href="#"
          onClick={() => handleTabSelect('home')}
          className={selectedTab === 'home' ? 'active' : ''}
        >
          <HomeOutlined />
          <p> Home</p>
        </a>
        <a
          href="#"
          onClick={() => handleTabSelect('chat')}
          className={selectedTab === 'chat' ? 'active' : ''}
        >
          <MessageOutlined />
          <p>Chat</p>
        </a>
      </div>
    </Card>
  );
}
export default ChatBot;