import { Button, Divider, Form, message, Modal } from "antd";
import { useContext } from "react";
import { authFetch } from "../../../../common/request/authFetch";
import { GlobalVariableContext } from "../../../../store/GlobalVariableContext/GlobalVariableContext";
import TimeRangeItem from "../../../../components/molecules/Forms/TimeRangeItem";
import dayjs from "dayjs";
import { useTranslation } from 'react-i18next';


export const RegularWeekScheduleEdit = ({location, edit, setEdit}) => {

	const { t } = useTranslation();
	const [shiftForm] = Form.useForm()
	const {updateGlobalData, setUpdateGlobalData } = useContext(GlobalVariableContext)

	var ranges = location.regularHours;
	var isOpen = location.regularHours.map(rh => rh.length > 0);

	const format = 'HH:mm';
	const formatTZ = 'YYYY-MM-DDTHH:mm[Z]';

	const rangesInit = ranges.map(
		(periods) => (periods.map((p) => ([ dayjs(`${p.openTimeHour}:${p.openTimeMinute}`, format), dayjs(`${p.closeTimeHour}:${p.closeTimeMinute}`, format)])))
		);

	const submitEdit = async (values) => {

		function getPeriods(values, day) {

			var ranges = values[day + '_Ranges'] || [];
			
			var periods = ranges && ranges.map((r) => ({ 
				openDayName: day,
				openTimeHour: r[0].hour(),
				openTimeMinute: r[0].minute(),
				openDayName: day,
				closeTimeHour: r[1].hour(),
				closeDayName: r[1].minute()
			}));
			
			return periods;
		}

		const WEEK = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']
		const periods = [];
		WEEK.forEach((value) => periods.push(getPeriods(values, value)));
		  
		const data = {
			field: 'REGULAR_HOURS',
			regularHours: periods
		};

		try {
			await authFetch(`${process.env.REACT_APP_API}/app/locations`, { method: 'PATCH', data: data })
				.then((res) => {

					if (res) {
						message.success('El horario se ha editado correctamente', 3);
						setEdit(false);
						setUpdateGlobalData(!updateGlobalData)

					} else {
						message.error(t('Error inesperado al editar el horario'), 3)
					}
				});
		} catch (error) {
			return message.error(error.message)
		}
	};

	return (
		<>
			<Form 
				layout='vertical' 
				form={shiftForm} 
				onFinish={(values) => submitEdit(values)}
				initialValues={{
					MONDAY_Open: isOpen[0], 
					TUESDAY_Open: isOpen[1],
					WEDNESDAY_Open: isOpen[2],
					THURSDAY_Open: isOpen[3],
					FRIDAY_Open: isOpen[4],
					SATURDAY_Open: isOpen[5],
					SUNDAY_Open: isOpen[6],
					MONDAY_Ranges: rangesInit[0],
					TUESDAY_Ranges: rangesInit[1],
					WEDNESDAY_Ranges: rangesInit[2],
					THURSDAY_Ranges: rangesInit[3],
					FRIDAY_Ranges: rangesInit[4],
					SATURDAY_Ranges: rangesInit[5],
					SUNDAY_Ranges: rangesInit[6]					
				}}>						

				<TimeRangeItem ranges={ranges[0]} field='MONDAY' label={t('dictionary.monday')}/>
				<TimeRangeItem ranges={ranges[1]} field='TUESDAY' label={t('dictionary.tuesday')}/>
				<TimeRangeItem ranges={ranges[2]} field='WEDNESDAY' label={t('dictionary.wednesday')}/>
				<TimeRangeItem ranges={ranges[3]} field='THURSDAY' label={t('dictionary.thursday')}/>
				<TimeRangeItem ranges={ranges[4]} field='FRIDAY' label={t('dictionary.friday')}/>
				<TimeRangeItem ranges={ranges[5]} field='SATURDAY' label={t('dictionary.saturday')}/>
				<TimeRangeItem ranges={ranges[6]} field='SUNDAY' label={t('dictionary.sunday')}/>

				{edit &&
					<Form.Item shouldUpdate style={{ marginTop: '15px' }}>
						{() => {
							return (
								<>
									<Button
										style={{ background: 'var(--primary)', color: 'white', marginLeft: '20px' }}
										onClick={
											() => {
												Modal.confirm({
													title: t('view.forms.modal.single.title'),
													//content: t('view.forms.modal.single.content', { field: t('dictionary.schedule').toLowerCase() }),
													okText: t('dictionary.confirm'),
													cancelText: t('dictionary.cancel'),
													onOk: shiftForm.submit
												});
											}
										}>
									{t('dictionary.save')}
								</Button>
									<Button
										style={{ background: 'white', color: 'dodgerblue', marginLeft: '20px', borderColor: 'dodgerblue' }}
										onClick={() => {
											shiftForm.resetFields();
											setEdit(false);
										}}
										type="primary">
										{t('dictionary.cancel')}
									</Button>
								</>
							)
						}}
					</Form.Item>}
			</Form>

		</>
	)
}