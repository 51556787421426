import { Collapse, Form, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FormItemOffer = () => {

  const { t } = useTranslation();	

  return (		
	<>
		<Collapse ghost>						
			<Collapse.Panel key="1" style={{background:'#f5f9fb', margin:'15px 0px'}} header={<>
				<b>Más detalle de la oferta</b>
				<small style={{ marginLeft: '10px', color: 'darkgrey' }}>
					Bloque de campos
				</small>
			</>}>
				<Form.Item label='Código del cupón' name='code'>
					<Input/>
				</Form.Item>							
				<Form.Item label='Enlace para canjear' name='redeemOnlineUrl' rules={[{ required: false, type: 'url' }]}>
					<Input/>
				</Form.Item>							
				<Form.Item label='Términos y condiciones' name='termsAndConditions'>
					<Input.TextArea/>
				</Form.Item>
			</Collapse.Panel>
		</Collapse>
	</>							
	)
}

export default FormItemOffer;