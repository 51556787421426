import { EditOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { InfoView } from '../InfoView';
import { EditWeb } from './EditWeb';

export const Web = ({ location }) => {

    const [edit, setEdit] = useState(false);
    const [currentURL, setCurrentURL] = useState(location?.websiteUri);
    const { t } = useTranslation();

    return (
        <div>
            {/* <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}> */}
            <span onClick={() => setEdit(true)}>
                <b className="title-box">Web <EditOutlined /></b>
            </span>

            {
                edit ? <EditWeb
                    url={location?.websiteUri}
                    locationId={location?.name}
                    setCurrentURL={setCurrentURL}
                    setEdit={setEdit}
                    edit={edit}
                /> :
                <InfoView data={location?.websiteUri} />
                
            }

        </div>
    )
}
