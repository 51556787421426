import React from "react";
import { Result } from "../../../../node_modules/antd/es/index"

export const Error404 = () => {

  return (
    <Result
    status="404"
    title="404"
    subTitle={'Recurso no existente'}    
  />)
}