import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Badge, Button, Card, Form, Input, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import logoLocalizameBackground from '../../assets/logo-localizame-background.png';
import { authFetch } from '../../common/request/authFetch';
import background from '../../assets/greyMap.png';
import "../../styles/Login.css"

export const RecoverPassword = () => {

	const [formError, setFormError] = useState(false);
	const [form] = Form.useForm();
	const location = useLocation();
	const navigate = useNavigate();
	const pathName = location.pathname.split('/');
	const email = pathName[pathName.length - 1];
	const verifyToken = new URLSearchParams(location.search).get('verificationToken');
	const { t } = useTranslation();

	useEffect(() => {
		form.setFieldsValue({ email: email });
	}, [])

	const onFinishReset = async (values) => {

		const data = {
			...values,
			email,
			verificationToken: verifyToken
		};

		await authFetch(`${process.env.REACT_APP_API}/users/recover?verificationToken=${verifyToken}`, { method: 'PUT', data })
			.then((isOk) => {
				if (isOk) {
					message.success(t('view.forms.modal.password.success'));
					navigate('/');
				} else {
					message.error(t('view.forms.modal.password.error'));
				}
			}
			);
	}

	return (
		// <><div style={{ position:'fixed', right:0, bottom:0,backgroundImage: `url(${planeBackground})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'right top', width: '100%', height: '100vh', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
		// 	<div style={{ backgroundImage: `url(${logoLocalizameBackground})`, backgroundSize: '45%', backgroundRepeat: 'no-repeat', backgroundPosition: 'right top', width: '100%', height: '80vh', display: 'flex', justifyContent: 'left', alignItems: 'center' }} />
		// </div>

		<>

			<div
				style={{ position: 'fixed', bottom: 0, right: 0, backgroundImage: `url(${background})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", width: '100%', height: '100vh', opacity: '0.5' }}

			/>

			<div className='login-general-two'>
				{/* <Badge.Ribbon placement='end' text={t('register.password.title')} color="#3498DB"> */}
				<div >

					<Card className="login-card" >
					<div className='login-form'>

						<div className='logo-header'>
						</div>
						
						<Form layout='vertical' form={form} name='basic' onFinish={onFinishReset} autoComplete='off'>
							<Form.Item name='email' 
							
							// label={t('dictionary.user')}
							>
								<Input
								disabled={true} 
								style={{color: 'rgb(39, 61, 115)'}}
								className='inputs disabled'
								prefix={<UserOutlined />} 
								/>
							</Form.Item>
							<Form.Item 
							// label={t('dictionary.password')} 
							name='password' 
							rules={[
								{
									required: true,
									message: t('register.password.required'),
								},
								{
									min: 8,
									message: t('register.password.characters'),
								},
								{
									pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+={}\[\]:;"'<>,.?/~`|\\-]*$/,
									message:  t('register.password.letters'),
								},
								{
									pattern: /^(?=.*[a-z])(?=.*[A-Z])/,
									message:  t('register.password.upperCase'),
								},
							]}
							// validateTrigger="onBlur"
							// rules={[{ required: true, message: t('register.password.required') }]}
							>
								<Input.Password 
								className='inputs'
								placeholder={t('register.password.placeholder')} 
								prefix={<LockOutlined />} 
								/>
							</Form.Item>
							<Form.Item 
							// label={t('register.password.confirm')} 
							name='password2' validateTrigger='onBlur' rules={[
								{
									required: true,
									message: t('register.password.required')
								},
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!value || getFieldValue('password') === value) {
											return Promise.resolve();
										}
										return Promise.reject(new Error(t('register.password.mismatch')));
									},
								}),
							]}>
								<Input.Password 
								className='inputs'
								placeholder={t('register.password.repeat')} prefix={<LockOutlined />} />
							</Form.Item>
							<Button 
							shape='round'
							width='30%'
							size='small'
							style={{ marginTop: '13px', backgroundColor: 'var(--accent)', border: 'black' }}
							htmlType="submit">
								{t('dictionary.changePassword')}
							</Button>
							<div></div>
						</Form>
						{formError && <Alert style={{ marginTop: '12px' }} type='error' message={t('login.error')} />}
						</div>

					</Card>
					{/* </Badge.Ribbon> */}
				</div>
			</div></>
	);
}
