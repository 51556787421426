import { Drawer, Card } from "antd"
import { RightOutlined, EyeFilled, StarFilled, LeftOutlined, SearchOutlined } from "@ant-design/icons"
import "../../styles/InformesDrawer.css"
export const InformesDrawer = ({ informesDrawer, setInformesDrawer }) => {


    return (
        <Drawer
            className="informes-drawer"
            onClose={() => setInformesDrawer(null)}
            placement='left'
            width={300}
            open={informesDrawer}
            closeIcon={<div style={{ backgroundColor: 'var(--white)', padding: '7px', borderRadius: '50%' }}><LeftOutlined /></div>}
            style={{ backgroundColor: 'rgba(225, 242, 255, 1)' }}
        >
            <div className="informes-container" >
                <h2>Informes</h2>

                <Card bodyStyle={{padding:'15px 20px'}}>
                    <ul className="informes-list">
                        <li><EyeFilled />Visibilidad</li>
                        <li><StarFilled />Reseñas</li>
                        {/* <li><AppstoreFilled />Geogrid</li> */}
                    </ul>
                </Card>
            </div>

            <div className="informes-container" >
                <h2>Análisis</h2>
                <Card bodyStyle={{padding:'15px 20px'}}>
                    <ul className="informes-list">
                        <li><SearchOutlined />Auditoría local</li>      
                    </ul>
                </Card>
            </div>
        </Drawer>
    )
}