import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ServiceItemsView } from './ServiceItemsView';

export const ServicesItems = ({ location}) => {

    const [edit, setEdit] = useState(false);

    const [categories, setCategories] = useState(location.categories);
    
    const { t } = useTranslation();

    const handleCancel = () => {
        setCategories(location.categories);
        setEdit(false);
    }

    const handleSuccess = (data) => {
        setCategories(location.categories);
        setEdit(false);
    }


    return (
        <div  
        >
            <span  
            // onClick={() => setEdit(true)}
            >
                <b className="title-box">Servicios 
                {/* <EditOutlined /> */}
                
                </b>
            </span>
          
            <ServiceItemsView serviceItems={location.serviceItems}/>            

        </div>
    )
}
