import { Form, TimePicker } from 'antd';
import { useState } from 'react';
import { Card, Switch } from '../../../../node_modules/antd/es/index';

const TimeRangeItem = ({ranges, field, label}) => {

	const [isOpen, setIsOpen] = useState(ranges.length > 0);
	
	const format = 'HH:mm';

	const openField = field + '_Open';
	const rangesField = field + '_Ranges';

	return (
		<Card style={{border:'1px solid Lavender', margin:'10px'}} bodyStyle={{padding:'5px 10px'}}>	
			<div style={{ display: "flex", justifyContent: 'space-between' }}>
				<b><small>{label}</small></b>		
				<Form.Item name={openField} valuePropName="checked">
					<Switch size='small' checkedChildren="Abierto" unCheckedChildren="Cerrado" /*defaultChecked={isOpen}*/ onChange={(e) => {setIsOpen(e)}}/>
				</Form.Item>							
			</div>
			{isOpen && 
				<Form.List name={rangesField}>
					{(fields, { add, remove }) => {
						return (
						<>						
							{
							fields.map((field, i) => (
								<div key={field.key}>
								{
									<div style={{ display: 'flex'}}>
										<Form.Item name={[i]} shouldUpdate style={{margin:'0px'}}>					
											<TimePicker.RangePicker size='small' format={format} disabled={!isOpen} minuteStep={10}/>
										</Form.Item>												
										{
										fields.length > 0 && 
											<button
												style={{ width: '10%', height: '32px', backgroundColor: "transparent", border: "none", fontWeight: 600, padding: "0 1em", cursor: "pointer" }}
												onClick={() => remove(field.name)} >
												X
											</button>
										}
									</div>
								}
								</div>
							))
							}
							{(fields.length <= 10) &&
							<button type='button' style={{ paddingBottom: "1em", border: "none", backgroundColor: "transparent", color: "#2781DD", fontWeight: 600, cursor: "pointer" }} onClick={() => add()}>
								+ Horario
							</button>}
						</>
						)
					}}
				</Form.List>
			}			
		</Card>
	)
}

export default TimeRangeItem;