import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, LikeOutlined, LoadingOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Form, Input, Modal, Spin, message } from "antd";
import { Comment } from '@ant-design/compatible';
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { getProjectStorage } from "../../store/storageContext";
import { authFetch } from "../../common/request/authFetch";
import { ButtonLZ } from "../../components/atoms/ButtonLZ";
import { STATUS } from "../../common/constants/statusFAQAndReviews";
import ReviewComment from "./ReviewComment";
import { Button, Image } from "../../../node_modules/antd/es/index";
import { BulbFilled, BulbOutlined, BulbTwoTone, LockFilled, RobotOutlined } from "../../../node_modules/@ant-design/icons/lib/icons/index";
import IAFilled from '../../assets/IA_logo.png';
import { GlobalVariableContext } from "../../store/GlobalVariableContext/GlobalVariableContext";
import { useIsAuthorized } from "../../common/constants/AuthUtils";

const ReviewAnswer = ({ getResponseIa,submitReply, replyForm, isLoadingIa, review, onFinish }) => {

    const { t } = useTranslation();
    const {setPlansDrawer } = useContext(GlobalVariableContext);
    const isAuthorized = useIsAuthorized();
    const [status, setStatus] = useState('');

    const deleteReply = async () => {
        const data = {
            reviewId: review.name
        };
        await authFetch(
            `${process.env.REACT_APP_API}/app/reviews/reply`,
            { method: 'DELETE', data }
        )
            .then((res) => {
                if (res) {
                    message.success(t('view.forms.modal.reviews.delete.success', { name: review.reviewerDisplayName }), 3);
                } else {
                    message.error(t('view.forms.modal.reviews.delete.error', { name: review.reviewerDisplayName }), 3);
                }
                onFinish();
            })
    }    
    
    return (
        <div style={{marginLeft:'40px'}}>

            <Comment     
                actions={
                    status !== STATUS.EDITING ? ([
                        <a className='review-btn-grey'
                            onClick={() => setStatus('Editing')}>
                            <EditOutlined style={{ marginRight: '2x' }} />
                            {t('dictionary.edit')}
                        </a>,
                        <a className='review-btn-grey'
                            onClick={() => {
                                Modal.confirm({
                                    title: t('reviews.page.reply.tabs.reviews.delete.confirm'),
                                    okText: t('dictionary.confirm'),
                                    cancelText: t('dictionary.cancel'),
                                    onOk: () => deleteReply()
                                })
                            }}
                        >
                            <DeleteOutlined style={{ marginRight: '2px' }} />
                            {t('dictionary.delete')}
                        </a>
                    ]) : undefined
                }
                author={
                    <div style={{ display: 'flex' }}>

                        <div style={{ color: 'black', fontSize: '14px' }}>
                            <Avatar style={{ backgroundColor: '#87d068', marginRight: '10px' }} icon={<UserOutlined />} />
                            {t('dictionary.owner')}
                        </div>

                    </div>
                }
            
                content={
                    status === STATUS.EDITING ? (
                        <Form layout='vertical'
                            form={replyForm}
                            onFinish={submitReply}
                        >
                            <Form.Item name='comment'>
                                <Input.TextArea
                                    className='comment-answer'
                                    defaultValue={review.reviewReplyComment.originalText || review.reviewReplyComment.text }
                                    style={{ width: '100%' }}
                                    rows={6}
                                />
                            </Form.Item>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center' }}>
                                {isAuthorized(['ROLE_APP_AI']) ?
                                    <Button
                                        onClick={() => {
                                        if (!isLoadingIa) {
                                            getResponseIa();
                                        }
                                        }}                      
                                        type='link'
                                        style={{ color: 'purple', borderColor: 'purple', backgroundColor: 'Lavender' }}>
                                            
                                            <Image preview={false} style={{height:'22px', width:'22px', opacity: 1}} src={IAFilled} /> 
                                            {isLoadingIa ? <Spin indicator={<LoadingOutlined spin />} /> : <b>Sugerencia IA</b>}
                                    </Button> 
                                    :
                                    <>
                                        <Button                                            
                                            type='link'     
                                            onClick={() => setPlansDrawer(true)}                                                                               
                                            style={{ color: 'purple', borderColor: 'purple', backgroundColor: 'Lavender', marginBottom:'10px' }}>
                                                <span><LockFilled style={{fontSize:'18px'}}/>  <b>Asistente Virtual</b></span>
                                        </Button>                                         
                                    </>
                                }         
                                <div>                   
                                    <ButtonLZ className='review-btn'                                    
                                        type='link'
                                        size='large'
                                        style={{ backgroundColor: 'white', color: 'dodgerblue', borderColor: 'dodgerBlue', margin:'5px', padding:'5px 12px' }}
                                        onClick={() => { replyForm.resetFields(); setStatus(''); }}
                                    >
                                        <CloseOutlined /> 
                                    </ButtonLZ>
                                    <ButtonLZ className='review-btn'                                    
                                        size='large'
                                        style={{ margin:'5px', padding:'5px 12px' }}
                                        onClick={() => { replyForm.submit(); setStatus(''); }}
                                    >
                                        <CheckOutlined /> 
                                    </ButtonLZ>
                                </div>
                            </div>
                        </Form>
                    ) : (
                        
                        !!review.reviewReplyComment.text && (
                            <ReviewComment comment={review.reviewReplyComment}/>
                        )
                    )
                }
            />
        </div>


    )
}

export default ReviewAnswer