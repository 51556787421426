import { Card, Pagination, Rate } from "antd"
import { CheckCircleTwoTone, ClockCircleTwoTone } from "@ant-design/icons"
import { calculateDays } from "../../common/utils/calculateDays"
import { ExpandableText } from "../../components/molecules/ExpandableText"
import googleLogo from "../../assets/logo_google.png"
import { useState } from "react"
import { Avatar, Button, Divider, Tag } from "../../../node_modules/antd/es/index"
import { CheckOutlined, ClockCircleOutlined, DoubleRightOutlined, DownSquareOutlined, EditOutlined, ForwardOutlined, UserOutlined } from "../../../node_modules/@ant-design/icons/lib/icons/index"

const itemsPerPage = 5;

export const ReviewsPaginated = ({ data, setSelectedReview }) => {
    
    const statusColor = {
        true: "YellowGreen",
        false: "Orange"
    }

    const [currentPage, setCurrentPage] = useState(1);
    
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, data?.length);
    const currentReviews = data?.slice(startIndex, endIndex);    
    
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    
    return (
        <>
        {currentReviews?.map((review) => {
            
            return <Card size="small" style={{borderBottom:`5px solid ${statusColor[!!review?.reviewReplyComment]}`}}>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <small >{calculateDays(review?.createdTime)}</small>
                    {review?.reviewReplyComment ?                               
                        <div><Tag onClick={()=>{setSelectedReview(review)}} color='green' style={{fontSize:'14px'}}><CheckOutlined/> Contestada</Tag></div>
                        :
                        <div><Tag onClick={()=>{setSelectedReview(review)}} color='orange' style={{fontSize:'14px'}}><ClockCircleOutlined/> Pendiente</Tag></div>                        
                    }
                </div>

                <div className="web-content-reviews-profile" style={{ position: 'relative' }}>
                    <div style={{ borderRadius: '50%', overflow: 'hidden', width: 50, height: 50, display: 'flex', justifyContent: 'center' }}>
                        <img src={review?.reviewerProfilePhotoUrl} style={{ height: '85%', width: 'auto' }} />
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                        <p style={{ margin: 0, fontWeight: 700 }}>{review?.reviewerDisplayName}</p>
                        <Rate style={{ fontSize: '15px', color: 'orange' }} disabled defaultValue={review?.starRating} />
                    </div>

                    <div style={{ background: 'white', padding: 5, position: 'absolute', width: 30, height: 30, borderRadius: '50%', top: '40%', left: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={googleLogo} style={{ height: '100%', width: 'auto' }} />
                    </div>

                </div>

                <ExpandableText comment={review?.comment}/>
                {review?.reviewReplyComment &&
                    <>                    
                        <Divider/>
                        <Avatar style={{ backgroundColor: 'lightgrey', marginRight: '6px' }} icon={<UserOutlined />} /> 
                        <b style={{color:'grey'}}>Propietario</b>
                        <ExpandableText comment={review?.reviewReplyComment}/>
                    </>
                }
                <div style={{display:'flex', justifyContent:'flex-end'}}>
                {review?.reviewReplyComment ?
                    <Button                                            
                        type='link'     
                        onClick={()=>{setSelectedReview(review)}}                                                                               
                        style={{ color: 'MidnightBlue', borderColor: 'MidnightBlue', backgroundColor: 'white', margin:'10px', borderRadius:'20px' }}>
                            <span><b>Editar</b></span>
                    </Button> 
                    :
                    <Button                                            
                        type='link'                             
                        onClick={()=>{setSelectedReview(review)}}                                                                               
                        style={{ color: 'white', borderColor: 'white', backgroundColor: 'var(--primary)', margin:'10px', borderRadius:'20px' }}>
                            <span><b>Contestar</b></span>
                    </Button> 
        }
                </div>
            </Card>
        })}

        <Pagination 
            style={{display:'flex', alignSelf:'flex-end'}}
            size="small"
            current={currentPage}
            total={data?.length}
            pageSize={itemsPerPage}
            onChange={handlePageChange} />
        </>

    )
}