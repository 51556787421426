import React from "react";
import { Result } from "../../../../node_modules/antd/es/index";

export const Error500 = () => {

  return (
    <Result
    status="500"
    title="500"
    subTitle={'Error en el servidor'}    
  />)
}
