import {useEffect} from 'react'
import { BrowserRouter } from "react-router-dom";
import './App.css';
import './App.less';
import { AuthProvider} from './store/AuthContext/AuthContext'
import  MainLayout  from './layouts/MainLayout'
import GlobalVariableProvider from './store/GlobalVariableContext/GlobalVariableContext';

function App() {
  useEffect(() => {
    document.title = "LOCALIZA.ME";
  }, []);

  return (
    <BrowserRouter>
      <GlobalVariableProvider>
        <AuthProvider>

          <MainLayout/>
          
      </AuthProvider>
      </GlobalVariableProvider>
    </BrowserRouter>
  );
}

export default App;
