import React, { useState } from 'react';
import { truncateText } from '../../common/utils/trucantedText';


export const ExpandableText = ({ comment }) => {
  
  const [allText, setAllText] = useState(false);
  
  const text = comment?.originalText || comment?.text || '';

  const truncatedText = truncateText(text, 100);
  const hasTruncatedText = truncatedText.length !== text.length;

  return (
    <div style={{ fontSize: '14px', fontWeight: '400' }}>
      {
        hasTruncatedText ? (
          allText ? (
            <>
              <span style={{color:'gray'}}>
                {text}
              </span>
              <a onClick={() => setAllText(false)}> Menos</a>
            </>
          ) 
          : 
          (
            <>
              <span style={{color:'gray'}}>
                {truncatedText}
              </span>
              <a onClick={() => setAllText(true)}> Más</a>
            </>
          )
        ) : (
          <span style={{color:'gray'}}>{text}</span>
        )
      }
    </div>
  );
};