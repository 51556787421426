import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "../store/AuthContext/AuthContext";
import { routes } from "./constants";
import { CustomFooter } from "../components/organism/CustomFooter";
import { CustomHeader } from "../components/organism/CustomHeader";


export const AppRoutes = () => {

    const { isAuth } = useAuth();

    return (
        <Routes>
            {
                routes.map((route, index) => {
                    return (
                        <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            element={
                                !route.isAuthenticated || isAuth ? (
                                    <>
                                        {route.viewHeader && <CustomHeader />}
                                        <route.component />
                                        {route.viewFooter && <CustomFooter />}

                                    </>
                                ) : (                                    
                                    <Navigate to="/login" />
                                )
                            }
                        >
                        </Route>
                    )
                })
            }
        </Routes>
    );
}
