export const CATEGORY = {
    CATEGORY_UNSPECIFIED: 'Adicional',
    COVER: 'Portada',
    PROFILE: 'Perfil',
    LOGO: 'Logo',
    EXTERIOR: 'Exteriores',
    INTERIOR: 'Interiores',
    PRODUCT: 'Producto',
    AT_WORK: 'En el trabajo',
    FOOD_AND_DRINK: 'Comida',
    MENU: 'Menu',
    COMMON_AREA: 'Áreas Comunes',
    ROOMS: 'Habitaciones',
    TEAMS: 'Equipo',
    ADDITIONAL: 'Adicional'
  }