import { Form, message } from "antd";
import { useAuth } from "../../../../../store/AuthContext/AuthContext";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { authFetch } from "../../../../../common/request/authFetch";
import { getProjectStorage } from "../../../../../store/storageContext";
import { EditableInput } from "../../../../../components/molecules/Forms/EditableInput";
import { GlobalVariableContext } from "../../../../../store/GlobalVariableContext/GlobalVariableContext";

export const EditName = ({name, setEdit, edit}) => {

    const {updateGlobalData, setUpdateGlobalData } = useContext(GlobalVariableContext);
    const [nameForm] = Form.useForm();
    const { t } = useTranslation();

    const submitName = async(values) => {
        const data = {
			field: 'NAME',
			name: values.name
		};

    await authFetch(`${process.env.REACT_APP_API}/app/locations`, { method: 'PATCH', data: data })
      .then((res) => {
        if (res) {
          message.success('El nombre se ha editado correctamente', 3);
          setEdit(false);
          setUpdateGlobalData(!updateGlobalData);
        } else {
          message.error(t('Error inesperado al editar el nombre'), 3)
        }
      });    
    }

    return (
        <div className="title-box">
            <Form layout='vertical' form={nameForm} onFinish={(values) => submitName(values)}>
              <EditableInput
                form={nameForm}
                value={name}
                field='name'
                onSubmitCallback={nameForm.submit}
                edit={edit}
                setEdit={setEdit}
                />
          </Form>
        </div>
    )
}

