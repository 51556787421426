import { Home } from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import { RecoverPassword } from "../pages/Login/RecoverPassword";
import { Notifications } from "../pages/Notifications/Notifications";
import { Posts } from "../pages/Posts/Posts";
import { Register, Step1Register } from "../pages/Register/Step1Register";
import { ReviewList } from "../pages/Reviews/ReviewList";
import { Location } from "../pages/Location/Location";
import { NewPost } from "../pages/Posts/NewPost";
import { WebPage } from "../pages/WebPage/WebPage";
import { Step2Register } from "../pages/Register/Step2Register";
import { Welcome } from "../pages/welcome/Welcome";
import { Error403 } from "../pages/common/errors/Error403";
import { Error404 } from "../pages/common/errors/Error404";
import { Error500 } from "../pages/common/errors/Error500";


export const routes = [
    {
        path: '/',
        component: Home,
        viewHeader: true,
        viewFooter: true,
        isAuthenticated: true,
        exact: true
    },
    {
        path: '/reviews',
        component: ReviewList,
        viewHeader: true,
        viewFooter: true,
        isAuthenticated: true,
        exact: true
    },
    {
        path: '/mybusiness',
        component: Location,
        viewHeader: true,
        viewFooter: true,
        isAuthenticated: true,
        exact: true
    },
    {
        path: '/posts',
        component: Posts,
        viewHeader: true,
        viewFooter: true,
        isAuthenticated: true,
        exact: true
    },
    {
        path: '/posts/newPost',
        component: NewPost,
        viewHeader: true,
        viewFooter: true,
        isAuthenticated: true,
        exact: true
    },
    {
        path: '/web',
        component: WebPage,
        viewHeader: true,
        viewFooter: true,
        isAuthenticated: true,
        exact: true
    },
    {
        path: '/notificaciones',
        component: Notifications,
        viewHeader: true,
        viewFooter: true,
        isAuthenticated: true,
        exact: true
    },
    {
        path: '/login',
        component: Login,
        viewHeader: false,
        viewFooter: false,
        isAuthenticated: false,
        exact: true
    },
    {
		path: '/register',
		component: Step1Register,
		viewHeader: false,
        viewFooter: false,
		isAuthenticated: false
	},
	{
		path: '/vincular',
		component: Step2Register,
		viewHeader: true,
        viewFooter: false,
		isAuthenticated: true
	},
    {
        path: '/welcome',
        component: Welcome,
        viewHeader: true,
        viewFooter: false,
        isAuthenticated: true,
    },
    {
		path: '/public/recover/*',
		component: RecoverPassword,
		viewHeader: false,
        viewFooter: false,
		isAuthenticated: false,
        exact: true
	},
    {
		path: '/error403',
		component: Error403,
		viewHeader: true,
        viewFooter: true,
		isAuthenticated: true,
        exact: true
	}
	,
	{
		path: '/error404',
		component: Error404,
		viewHeader: true,
        viewFooter: true,
		isAuthenticated: true,
        exact: true
	}
	,
	{
		path: '/error500',
		component: Error500,
		viewHeader: true,
        viewFooter: true,
		isAuthenticated: true,
        exact: true
	}
]