import { MinusCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import { Button, Form, Input, Modal, Switch, TimePicker, message } from 'antd';
import { SetStateAction, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalVariableContext } from '../../../../../store/GlobalVariableContext/GlobalVariableContext';
import { useAuth } from '../../../../../store/AuthContext/AuthContext';
import { authFetch } from '../../../../../common/request/authFetch';
import { Card } from '../../../../../../node_modules/antd/es/index';
import dayjs from "dayjs";

export const FestiveScheduleEdit = ({
	//locationIds,
	ranges,
	festiveDate,
	onCancel,
	onFinish
}) => {
	
	const [shiftForm] = Form.useForm();

	const [isOpen, setIsOpen] = useState(!ranges[0].closed);
	const {updateGlobalData, setUpdateGlobalData } = useContext(GlobalVariableContext)

	const { t } = useTranslation();

	const format = 'HH:mm';
	const formatTZ = 'YYYY-MM-DDTHH:mm[Z]';	

	const initialValues = ranges.filter((period) => !period.closed).map((period) => 
	([ dayjs(`${period.openTimeHour}:${period.openTimeMinute}`, format), dayjs(`${period.closeTimeHour}:${period.closeTimeMinute}`, format)]
	));

	const submitEdit = async (values) => {
		
		// When open ranges are mandatory
		if (values.openCheckbox && values.festives?.length === 0) {
			return message.warning('Se necesita un rango horario');
		}

		const timeRanges = values.festives && values.festives.map((timeRange) => ({openTime: timeRange[0].format(formatTZ), closeTime: timeRange[1].format(formatTZ)}));

		const data = {
			field: 'SPECIAL_HOURS',
			specialHours: {
				timeRanges : timeRanges,
				open: values.openCheckbox,
				festiveDate: festiveDate
			},			
			//locationsNames: locationIds
		};

		try {
			await authFetch(`${process.env.REACT_APP_API}/app/locations`, { method: 'PATCH', data: data })
				.then((res) => {

					if (res) {
						message.success(t('view.forms.modal.single.success', { field: 'festivo' }), 3);
						onFinish();
						setUpdateGlobalData(!updateGlobalData);
					} else {
						message.error(t('view.forms.modal.single.error'), 3)
					}
				});

		} catch (error) {
			return message.error(error.message)
		}
	};

	return (

		<Form layout='vertical' form={shiftForm} onFinish={(values) => submitEdit(values)} initialValues={{openCheckbox: !ranges[0].closed, festives: initialValues}}>
			<>
			{
				<Form.Item name={'openCheckbox'} valuePropName="checked" style={{ width: '10%', marginBottom: '10px' }}>
					<Switch size='small' checkedChildren="Abierto" unCheckedChildren="Cerrado" defaultChecked={isOpen} onChange={(e) => {setIsOpen(e)}}/>
				</Form.Item>
			}
			
			{isOpen && 
				<Form.List name="festives">
					{(fields, { add, remove }) => {
						return (
						<Card style={{background:'GhostWhite'}}>						
							{
							fields.map((field, i) => (
								<div key={field.key}>
								{
									<div style={{ display: 'flex' }}>
										<Form.Item name={[i]} shouldUpdate>					
											<TimePicker.RangePicker  format={format} disabled={!isOpen} minuteStep={10}/>
										</Form.Item>												
										{
										fields.length > 0 && 
											<button
												style={{ width: '10%', height: '32px', backgroundColor: "transparent", border: "none", fontWeight: 600, padding: "0 1em", cursor: "pointer" }}
												onClick={() => remove(field.name)} >
												X
											</button>
										}
									</div>
								}
								</div>
							))
							}
							{(fields.length <= 10) &&
							<button type='button' style={{ paddingBottom: "1em", border: "none", backgroundColor: "transparent", color: "#2781DD", fontWeight: 600, cursor: "pointer" }} onClick={() => add()}>
								+ Añadir Rango Horario
							</button>}
						</Card>
						)
					}}
				</Form.List>
			}						
			{		
				<Form.Item shouldUpdate style={{ marginTop: '15px' }}>
					{() => {
						return (
							<>
								<Button
									style={{ background: 'var(--primary)', color: 'white', marginLeft: '20px' }}
									onClick={
										() => {
											Modal.confirm({
												title: t('view.forms.modal.single.title'),
												okText: t('dictionary.confirm'),
												cancelText: t('dictionary.cancel'),
												onOk: shiftForm.submit
											});
										}
									}
								>
									{t('dictionary.save')}
								</Button>
								<Button
									style={{ background: 'white', color: 'dodgerblue', marginLeft: '20px', borderColor: 'dodgerblue' }}
									onClick={() => {
										shiftForm.resetFields();																						
										onCancel();
									}}
									type="primary">
									{t('dictionary.cancel')}
								</Button>
							</>
						)
					}}
				</Form.Item>
			}				
			</>		
		</Form>
	)
}