//import { useTranslation } from 'react-i18next';

export const Period = ({data}) => {  

 //const { t } = useTranslation();

 let openTimeMinuteString= data.openTimeMinute.toString();
 let closeTimeMinuteString= data.closeTimeMinute.toString();

  return (
    <div>
        <p style={{color:'SeaGreen'}}>
          {data.openTimeHour}:{openTimeMinuteString.length > 1 ? data.openTimeMinute : "00" } - {data.closeTimeHour}:{closeTimeMinuteString.length > 1 ? data.closeTimeMinute : "00" } 
        </p>       
    </div>
  )
}
