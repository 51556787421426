import { NotificationOutlined, TagOutlined, CalendarOutlined } from "@ant-design/icons"
import { Card, Form, Tabs, message } from "antd"
import FormItemSummary from "../../components/molecules/Forms/FormItemSummary"
import FormItemUploadPhoto from "../../components/molecules/Forms/FormItemUploadPhoto"
import FormItemCallToAction from "../../components/molecules/Forms/FormItemCallToAction"
import FormItemTitle from "../../components/molecules/Forms/FormItemTitle"
import FormItemEvent from "../../components/molecules/Forms/FormItemEvent"
import FormItemOffer from "../../components/molecules/Forms/FormItemOffer"
import { authFetch } from "../../common/request/authFetch"
import { useTranslation } from "react-i18next"
import PublishButton from "../../components/molecules/PublishButton"
import { useNavigate } from "../../../node_modules/react-router-dom/dist/index"
import { Divider } from "../../../node_modules/antd/es/index"

export const NewPost = () => {

	const navigate = useNavigate();
	
	const [form1] = Form.useForm();
	const [form2] = Form.useForm();
	const [form3] = Form.useForm();

	const { t } = useTranslation();

	const submitStandard = async (values) => {

		const data = {
			summary: values.summary,
			callToActionType: values.callToActionType,
			callToActionURL: values.callToActionURL,
			callToActionURLLocal: values.callToActionURLLocal,
			locationsNames: [],
			photosURL: values.photo != null ?
				values.photo.fileList.map((file) => file.url)
				:
				[]
		};

		singlePublish(data, 'localPostStandard');
	};

	const submitOffer = async (values) => {

		const data = {
			summary: values.summary,
			eventTitle: values.title,
			eventScheduleStart: values.dateRangeStart.format('YYYY-MM-DDTHH:mm[Z]'),
			eventScheduleEnd: values.dateRangeEnd.format('YYYY-MM-DDTHH:mm[Z]'),
			offerCouponCode: values.code,
			offerRedeemOnlineUrl: values.redeemOnlineUrl,
			offerTermsConditions: values.termsAndConditions,
			locationsNames: [],
			photosURL: values.photo != null ?
				values.photo.fileList.map((file) => file.url)
				:
				[]
		};

		singlePublish(data, 'localPostOffer');
	};

	const submitEvent = async (values) => {

		const data = {
			summary: values.summary,
			eventTitle: values.title,
			eventScheduleStart: values.dateRangeStart.format('YYYY-MM-DDTHH:mm[Z]'),
			eventScheduleEnd: values.dateRangeEnd.format('YYYY-MM-DDTHH:mm[Z]'),
			callToActionType: values.callToActionType,
			callToActionURL: values.callToActionURL,
			locationsNames: [],
			photosURL: values.photo != null ?
				values.photo.fileList.map((file) => file.url)
				:
				[]
		};
		singlePublish(data, 'localPostEvent');
	};

	const singlePublish = async (data, type) => {
		await authFetch(`${process.env.REACT_APP_API}/app/posts/` + type, { method: 'POST', data: data })
			.then((res) => {
				if (res) {
					message.success('La publicación se ha realizado con éxito', 5);
					navigate('/posts')
				} else {
					message.error('Problema inesperado en la publicación', 5);
				}
			});
	}

	return (
		<div style={{margin:'0px 15px'}}>
			<h3 style={{color:'var(--primary)'}}>Nueva publicación <span style={{fontSize:'30px'}}></span></h3>
			<br/>
			<Card size="small" style={{minHeight:'80vh', padding:'10px'}}>
				<Tabs defaultActiveKey="1" >
					
					<Tabs.TabPane id='tabs' tab={<span><NotificationOutlined />Novedad</span>} key="1" style={{ marginTop: '20px' }}>
						<Form layout='vertical' form={form1} onFinish={submitStandard}>
							<FormItemSummary isRequired={true} form={form1} type='Novedad' />							
							<FormItemUploadPhoto maxPhotos={1} />
							<FormItemCallToAction />
						</Form>				
						<PublishButton to='/posts' type='Novedad' onClick={form1.submit}/>
					</Tabs.TabPane>
					
					<Tabs.TabPane tab={<span><TagOutlined />Ofertas</span>} key="2" style={{ marginTop: '20px' }}>
						<Form layout='vertical' form={form2} onFinish={submitOffer}>
							<FormItemTitle />
							<FormItemSummary isRequired={true} form={form2} type='Oferta' />
							<FormItemEvent/>
							<FormItemUploadPhoto maxPhotos={1} />
							<FormItemOffer />							
						</Form>
						<PublishButton to='/posts' type='Oferta' onClick={form2.submit}/>
					</Tabs.TabPane>
					
					<Tabs.TabPane tab={<span><CalendarOutlined />Evento</span>} key="3" style={{ marginTop: '20px' }}>
						<Form layout='vertical' form={form3} onFinish={submitEvent}>
							<FormItemTitle />
							<FormItemSummary isRequired={true} form={form3} type='Evento' />
							<FormItemEvent/>
							<FormItemUploadPhoto maxPhotos={1} />
							<FormItemCallToAction />
						</Form>

						<PublishButton to='/posts' type='Evento' onClick={form3.submit}/>
					</Tabs.TabPane>
				</Tabs>
			</Card>
		</div>
	)
}