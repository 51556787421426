import { useState } from "react";
import { CheckCircleFilled, InfoCircleOutlined, YoutubeFilled } from "../../../../node_modules/@ant-design/icons/lib/icons/index";
import { Card, Drawer } from "../../../../node_modules/antd/es/index";
import { VideoDrawer } from "./VideoDrawer";

export const VideoIndexDrawer = ({videos, onFinish}) => {    

    const [videoDrawer, setVideoDrawer] = useState(null);

    return (
        <>
            <Drawer
                closeIcon={null}
                onClose={onFinish}
                placement='right'
                width={'90%'}
                open={true}
                style={{ background: 'linear-gradient(to bottom, rgba(46, 199, 255, 1), rgba(5, 159, 255, 1))', color: 'white' }}
            >

                <h1><YoutubeFilled/> Tutoriales</h1>                        
                <br/><br/>
                <h2>Youtube:</h2>                        
                <Card style={{marginTop:'15px'}}>
                    {videos.map((video, i) => (                                           
                        <div style={{marginTop:'15px', fontSize:'17px'}}>
                            <YoutubeFilled style={{color:'red'}} /> 
                            <b><a onClick={() => {setVideoDrawer(video)}}> {video.title}</a></b>
                        </div>                            
                    ))}                    
                </Card>
            </Drawer>
            {videoDrawer && <VideoDrawer video={videoDrawer} onFinish={() => {
                setVideoDrawer(false);
            }}/>}
        </>
    )
}