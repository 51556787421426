import { Footer } from "antd/es/layout/layout"
import { StarFilled, HomeFilled, EnvironmentFilled, NotificationFilled, GlobalOutlined } from '@ant-design/icons';

import "../../styles/CustomFooter.css"
import { Link } from "react-router-dom";
import { Menu } from "antd";
import { useState } from "react";
import { NotificationOutlined, ShopFilled } from "../../../node_modules/@ant-design/icons/lib/icons/index";
import { useLocation } from "../../../node_modules/react-router-dom/dist/index";

export const CustomFooter = () => {

    const location = useLocation();
  
    return (        
      <Footer className="custom-footer" >
        {/*<Link className={`link-footer ${location.pathname === '/' && 'selected'}`} to={"/"}>
          <HomeFilled style={{ fontSize: '20px' }} />
          <small>Inicio</small>
        </Link>*/}
        <Link className={`link-footer ${location.pathname === '/mybusiness' && 'selected'}`} to={"/mybusiness"}>
          <ShopFilled style={{ fontSize: '20px' }} />
          <small>Mi Ficha</small>                
        </Link>
        <Link className={`link-footer ${location.pathname === '/web' && 'selected'}`} to={"/web"}>
          <GlobalOutlined style={{ fontSize: '20px' }} />
          <small>Mi Web</small>                
        </Link>
        <Link className={`link-footer ${location.pathname === '/reviews' && 'selected'}`} to={"/reviews"}>
          <StarFilled style={{ fontSize: '20px' }} />
          <small>Reseñas</small>                
        </Link>
        <Link className={`link-footer ${location.pathname === '/posts' && 'selected' ? 'selected' : ''}`} to={"/posts"}>
          <NotificationFilled style={{ fontSize: '20px' }} />
          <small>Posts</small>                
        </Link>
      </Footer>
    );
}