import { Button, Card, Divider, Switch, Tag, Tooltip } from "antd"
import { DownOutlined, UpOutlined, CheckCircleFilled } from '@ant-design/icons';
import { useState } from "react";
import IA from "../../../assets/IaFilled.png";
import web from "../../../assets/webFilled.png";
import { Badge, Drawer, Skeleton, message } from "../../../../node_modules/antd/es/index";
import { useFetch } from "../../../hooks/useFetch";
import { ButtonLZ } from "../../../components/atoms/ButtonLZ";
import { authFetch } from "../../../common/request/authFetch";
import { useAuth } from "../../../store/AuthContext/AuthContext";

export const PlansDrawer = ({onFinish}) => {

    const [updateData, setUpdateData] = useState(false);
    const { profile } = useAuth();
    const [addInfoIA, setAddInfoIA] = useState(false);
    const [addInfoWeb, setAddInfoWeb] = useState(false);

    const {data, isLoading} = useFetch(`${process.env.REACT_APP_API}/app/modules`, {}, [updateData]);

    const activateModule = async(moduleName) => {
        
        await authFetch(`${process.env.REACT_APP_API}/app/modules/${moduleName}`, { method: 'PATCH', data : {module : moduleName} })
        .then((res) => {
            if (res) {
                message.success('Solicitud aceptada, email con el enlace de pago enviado', 12);
                setUpdateData(!updateData);
            } else {
                debugger;
                message.error('Error inesperado al solicitar el módulo', 5)
            }
        });    
    }

    return (
        <Drawer
            closeIcon={null}
            onClose={onFinish}
            placement='right'
            width={'70%'}
            open={true}
            style={{ background: 'linear-gradient(to bottom, rgba(46, 199, 255, 1), rgba(5, 159, 255, 1))', color: 'white' }}
        >

            <h1>Módulos extra</h1>
            <b>Escala tu negocio</b>
            <br/><br/>

            {isLoading?
                <>
                    <Skeleton/>    
                    <Skeleton/>    
                </>
                :
                <div style={{ margin: '0px', display: 'flex', flexDirection: 'column', gap: '20px' }}>

                    <Badge.Ribbon text="9€/mes" color='purple'>
                        <Card style={{border:`${data?.ai?.paid && '3px'} solid MediumPurple`}}>
                        <div style={{position:'absolute', top:5, left:5}}><img src={IA} style={{width:'20%'}}/></div>

                            <div style={{textAlign: 'center'}}>
                                <h2><b style={{color:`${data?.ai?.paid ? 'purple' : 'black'}`}}>IA</b></h2>
                                <h4 style={{color:'grey'}}>Tu asistente virtual</h4>
                                <p style={{marginTop:'13px'}}>Nunca fue tan fácil contestar reseñas</p>
                            </div>
                            
                            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0', alignItems: 'center' }}>
                                
                                <Button 
                                    style={{ borderColor: 'var(--blue-tag)', color: 'var(--blue-tag)' }} size="small"
                                    onClick={()=>{setAddInfoIA(!addInfoIA)}}>                                
                                    {addInfoIA ? <small>Ver menos <UpOutlined /> </small>: <small>Ver más <DownOutlined /></small>}
                                </Button>
                                <div style={{marginLeft:'5px'}}>
                                    {data?.ai?.active ?
                                        <>
                                            <Tag color={'purple'}><b>{data?.ai?.paid ? <>ACTIVADO</> : <>PENDIENTE</>}</b></Tag>                        
                                        </>
                                        :
                                        <>
                                            {profile?.roles?.length > 0 && <ButtonLZ onClick={() => {activateModule('AI')}} style={{ background: 'linear-gradient(rgba(255, 165, 0, 0.8), rgba(255, 165, 0, 1))' }}>
                                                <b>Activar</b>
                                            </ButtonLZ>}
                                        </>
                                    }
                                </div>
                            </div>

                            {addInfoIA && <div >
                                <Divider/>
                                <ul style={{listStyle:'none', backgroundColor:'#8ee3ff2b', padding:'10px', borderRadius:'20px'}}>                            
                                <li><CheckCircleFilled style={{color:'var(--blue-tag)', marginRight:'10px'}}/><b>Reseñas</b> Sugerencias de respuestas con IA</li>
                                <li><CheckCircleFilled style={{color:'var(--blue-tag)', marginRight:'10px'}}/><b>Publicaciones</b> Ayuda a desarrollar los textos </li>
                            </ul>
                            </div>}
                        </Card>
                    </Badge.Ribbon>
                                
                <Badge.Ribbon text="9€/mes" color='dodgerblue'>
                <Card style={{border:`${data?.ai?.paid && '3px'} solid blue`}}>
                    <div style={{position:'absolute', top:5, left:5}}><img src={web} style={{width:'20%'}}/></div>

                        <div style={{textAlign: 'center'}}>
                            <h2><b style={{color:`${data?.ai?.paid ? 'blue' : 'black'}`}}>Web</b></h2>
                            <h4 style={{color:'grey'}}>Configurable por ti</h4>
                            <p style={{marginTop:'13px'}}>Generada a partir de los datos de tu ficha de Google</p>
                        </div>
                    
                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0', alignItems: 'center' }}>                        

                        <Button 
                                style={{ borderColor: 'var(--blue-tag)', color: 'var(--blue-tag)' }} size="small"
                                onClick={()=>{setAddInfoWeb(!addInfoWeb)}}>   
                                {addInfoWeb ? <small>Ver menos <UpOutlined /> </small>: <small>Ver más <DownOutlined /></small>}
                            </Button>
                            <div style={{marginLeft:'5px'}}>
                                {data?.web?.active?
                                    <>                                            
                                        <Tag color={'blue'}><b>{data?.web?.paid ? <>ACTIVADO</> : <>PENDIENTE</>}</b></Tag>  
                                        <small>{data?.web?.paid}</small>
                                    </>
                                    :
                                    <>
                                        {profile?.roles?.length > 0 && 
                                            <ButtonLZ onClick={() => {activateModule('WEB')}} style={{ background: 'linear-gradient(rgba(255, 165, 0, 0.8), rgba(255, 165, 0, 1))' }}>
                                                <b>Activar</b>
                                            </ButtonLZ>  
                                }            
                                        </>
                                    }
                                </div>
                        </div>

                        {addInfoWeb && 
                            <div>
                                <Divider/>
                                <ul style={{listStyle:'none', backgroundColor:'#8ee3ff2b', padding:'10px', borderRadius:'20px'}}>                            
                                    <li><CheckCircleFilled style={{color:'var(--blue-tag)', marginRight:'10px'}}/>Se crea de forma automática</li>                            
                                    <li><CheckCircleFilled style={{color:'var(--blue-tag)', marginRight:'10px'}}/>Actualizada de los datos de tu ficha</li>
                                    <li><CheckCircleFilled style={{color:'var(--blue-tag)', marginRight:'10px'}}/>Elige tu mismo el color</li>
                                </ul>
                            </div>
                        }
                    </Card>
                </Badge.Ribbon>
              
            </div>
            }
        </Drawer>
    )
}