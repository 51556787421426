import { useState } from "react";
import photo from "../../../../assets/background/photo_icon.png"
import SwiperCarousel from "../../../../components/molecules/Swiper/Swiper";
import { ButtonLZ } from "../../../../components/atoms/ButtonLZ";
import { Card } from "antd";
import { CATEGORY } from "../../../../common/constants/mediaItems";
import { PostImages } from "./PostImages";

export const PhotosTab = ({ location, mediaItems }) => {

    const [postImagesVisible, setPostImagesVisible] = useState(false);

    // Agrupar mediaItems por categoría
    const groupedMediaItems = {};
    mediaItems && mediaItems.forEach(item => {
        if (!groupedMediaItems[item.category]) {
            groupedMediaItems[item.category] = [];
        }
        groupedMediaItems[item.category].push(item);
    });

    return (
        <div>
            {mediaItems && mediaItems.length > 0 ? (
                <Card>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
                        <ButtonLZ 
                        onClick={() => { setPostImagesVisible(true) }}

                        >Añadir Fotos</ButtonLZ>
                    </div>
                    {Object.keys(groupedMediaItems).map(category => (
                        <div key={category}>
                            <h3 style={{margin:'10px 0'}}>{CATEGORY[category]}</h3>
                            <SwiperCarousel
                                mediaItems={groupedMediaItems[category]}
                            />

                        </div>
                    ))}</Card>
            ) : (
                <div
                    style={{
                        position: 'relative',
                        height: '300px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <h1 style={{ color: '#AAADAE', fontWeight: 700, fontSize: '40px' }}>
                        No tienes fotos!
                    </h1>
                    <ButtonLZ color="yellow" onClick={() => { setPostImagesVisible(true) }} >Subir fotos</ButtonLZ>

                    {/* <img src={circleGreen} style={{ position: 'absolute', width: '40px', bottom: '15%', left: '15%' }} />
                    <img src={donutYellow} style={{ position: 'absolute', width: '270px', bottom: 0, right: 0 }} /> */}
                    <img src={photo} style={{ position: 'absolute', width: '100px', top: '5%', right: '5%' }} />
                    <img src={photo} style={{ position: 'absolute', width: '70px', bottom: '20%', left: 0, transform: 'rotate(-50deg)' }} />

                    {/* <img src={bluePill} style={{ position: 'absolute', width: '80px', bottom: '10%', right: 0, transform: 'rotate(100deg)'}} /> */}
                </div>
            )}

            {
                postImagesVisible && <PostImages
                    location={location}
                    visible={postImagesVisible}
                    onFinish={() => {
                        setPostImagesVisible(false);
                    }}
                    onCancel={() => {
                        setPostImagesVisible(false);
                    }} />
            }
        </div>
    );
};
