import { useAuth } from "../../store/AuthContext/AuthContext";

/*export const IsAuthorized = (roles) => {
    const { profile } = useAuth();
    let isAuthorized = false;
    debugger;
    roles.forEach(role => {
        if (profile.roles && profile.roles.map(r => r.key).includes(role)) { 
            isAuthorized = true;
        }
    })
    return isAuthorized
}*/

export const useIsAuthorized = () => {

    const { profile } = useAuth();

    return (roles) => {        
        
        //debugger;
        let isAuthorized = false;
        roles.forEach(role => {
            if (profile.roles && profile.roles.includes(role)) { 
                isAuthorized = true;
            }
        })

        return isAuthorized;
    }
}