import axios from 'axios';
import { useState } from 'react';
import { googleLogout } from '@react-oauth/google';
import {
	getTokenForStorage, removeToken, storeToken
} from '../../store/storage';
import { getGroupAccountStorage, getProjectStorage } from '../../store/storageContext';



export const useAuthProvider = () => {
	const startingToken = getTokenForStorage();
	const startingProject = getProjectStorage();
	const [project, setProject] = useState(startingProject);
	const startingGroupAccount = getGroupAccountStorage(project);
	const [groupAccount, setGroupAccount] = useState(startingGroupAccount);
  	const [isAuth, setIsAuth] = useState(!!startingToken);
	const [token, setToken] = useState(startingToken);
	const [profile, setProfile] = useState({name:'', email:'', role:'', projects:[]});
	const [collapsed, setCollapsed] = useState(false);		

	const logIn = (data) => {
		return axios.post(`${process.env.REACT_APP_API}/authenticate`, data)
			.then((response) => {				
				const token = (response).data.token;
				storeToken(token);
				setToken(token);
				setIsAuth(true);
				return Promise.resolve({
					statusCode: response.status,
					message: "OK",
					success: true
				}) 
			})
			.catch((err) => {
				return Promise.resolve({
					statusCode: err.response.status,
					message: err.message,
					success: false
				})
			});
	};

	const logOut = () => {
		removeToken();
		setToken(null);
		setIsAuth(false);
	};

	const getHeader = () => {			
		return '';//`/projects/${project}/${groupAccount}`;
	};

	const googleLogIn = (data, project) => {

		if (!data.code) {
			return Promise.resolve(false);
		}

		const googleAuthToken = data.code;
		const config = {
			headers: {
				'Authorization': 'Bearer ' + token
			}
		}

		return axios.post(
				`${process.env.REACT_APP_API}/users/principal/google/authenticate`,
				{ authCode: googleAuthToken, project: project },
				config
			).then(() => Promise.resolve(true))
			.catch(() => Promise.resolve(false));
	}

	const googleLogOut = (project) => {
		const config = {
			headers: {
				'Authorization': 'Bearer ' + token
			}
		}

		return axios.post(
				`${process.env.REACT_APP_API}/users/principal/google/unauthenticate`,
				{ project: project },
				config
			).then(() => {
				googleLogout();
				window.document.location.reload();
			})
			.catch(() => Promise.resolve(false));
	}

	return {
		token,
		isAuth,
		logIn,
		logOut,
		googleLogIn,
		googleLogOut,
		getHeader,
		project,
		setProject,
		groupAccount,
		setGroupAccount,
		profile,
		setProfile,
		collapsed,
		setCollapsed
	}
}