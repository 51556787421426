
import { t } from 'i18next';
import { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { LocationDetailHours, RegularWeekScheduleEdit } from './RegularWeekScheduleEdit';
import { FormHorario, RegularDayScheduleView } from '../../../../components/molecules/Forms/RegularDayScheduleView';
import { CloseOutlined } from '../../../../../node_modules/@ant-design/icons/lib/icons/index';

const NormalSchedule = ({ location }) => {
    
    const [edit, setEdit] = useState(false);    

    return (

        <div>
     
            <span>
                <b className="title-box">
                    Horario
                    {edit? 
                        <CloseOutlined onClick={() => setEdit(!edit)}/> 
                        : 
                        <EditOutlined onClick={() => setEdit(!edit)}/>}
                </b>
            </span>

            {location?.regularHours && !edit ?
                <div style={{margin:'0px 15px'}}>
                    <div style={{ margin: "1em 0", display: "flex",  flexDirection:'column', justifyContent: "flex-start" }}>
                        <RegularDayScheduleView title="Lunes" regularHour={location.regularHours[0]} />
                        <RegularDayScheduleView title="Martes" regularHour={location.regularHours[1]} />
                        <RegularDayScheduleView title="Miércoles" regularHour={location.regularHours[2]} />
                        <RegularDayScheduleView title="Jueves" regularHour={location.regularHours[3]} />
                        <RegularDayScheduleView title="Viernes" regularHour={location.regularHours[4]} />
                        <RegularDayScheduleView title="Sábado" regularHour={location.regularHours[5]} />
                        <RegularDayScheduleView title="Domingo" regularHour={location.regularHours[6]} />
                    </div>
                </div>
                :
                <RegularWeekScheduleEdit 
                    location={location}
                    setEdit={setEdit}
                    edit={edit} />
            }
        </div>

    );
};

export default NormalSchedule;