import { Tag } from "antd"

export const InfoView = ({data, isTag}) => {
    return(
        <div className="title-box">
        <div style={{ display: "flex", justifyContent: "flex-start", marginLeft:'5px' }}>
            <span style={{ color: "#666" }}>
                {data} {isTag ? <Tag>Principal</Tag> : null}
            </span>
        </div>                
      </div> 
    )
}