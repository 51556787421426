import { Card, Form, Steps, message, Input, Alert } from "antd";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { authRequest } from "../../common/request/authRequest";
import { useAuth } from "../../store/AuthContext/AuthContext";
import { ButtonLZ } from "../../components/atoms/ButtonLZ";
import "../../styles/Register.css"
import { CustomerServiceOutlined, InfoCircleFilled, InfoCircleOutlined, InfoOutlined, QuestionCircleOutlined, SyncOutlined, WhatsAppOutlined, YoutubeFilled } from "../../../node_modules/@ant-design/icons/lib/icons/index";
import { Button, FloatButton } from "../../../node_modules/antd/es/index";
import { GlobalVariableContext } from "../../store/GlobalVariableContext/GlobalVariableContext";
import { VideoDrawer } from "../common/videos/VideoDrawer";
import { VideoIndexDrawer } from "../common/videos/VideoIndexDrawer";

export const Step1Register = () => {

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { logIn, logOut, setProfile } = useAuth();
    
    const [formError, setFormError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { t } = useTranslation();
    
    const [isVideoDrawer, setIsVideoDrawer] = useState(false);

    logOut();

    const onFinishRegister = async (values) => {
        const data = {
            ...values,
            status: 'REGISTERED',
        };
                
        const loginData = {
            username: values.email,
            password: values.password
        };

        authRequest(`${process.env.REACT_APP_API}/users/register/app`, { method: 'PUT', data })
            .then(() => {
                logIn(loginData)
                .then(() => {				
                    navigate('/vincular')
                })
                message.success('Tus datos se han guardado con éxito', 3);
                //navigate('/vincular');
                setFormError(false);
            }).catch((error) => {
                setFormError(true);
                !!error.data.message ?
                    setErrorMessage(error.data.message) : setErrorMessage(t('view.forms.modal.register.error'));
            }
            );
    };

    return (

        <div style={{ display: 'flex', flexDirection:'column', alignItems: 'center', marginTop:'15px' }}>    
        
        <h2 style={{color:'MidnightBlue'}}>LOCALIZA.ME<h3 style={{fontSize:'10px'}}>Mi <del>Pequeño </del>GRAN negocio</h3></h2>                    
        <div style={{ display: 'flex', alignItems: 'center' }}>

            <Card bordered={true} style={{ margin: '10px', width: '100%', minHeight: '90%', border: '.5px solid lightgrey', borderRadius: '10px', background: 'white' }}>
                <h3 style={{paddingBottom:'17px'}}>Registro: 1 de 2</h3>

                <div style={{ padding: '0 0 15px 0' }}>
                <Steps current={0} status={formError ? "error" : "process"} >
                    <Steps.Step title={<>Introduce tus datos</>} />
                    <Steps.Step title={<small>Vincula tu negocio</small>} />
                </Steps>
                </div>
                <Form
                    form={form}
                    size="small"
                    layout="horizontal"                    
                    //labelCol={{ span: 4 }}
                    //wrapperCol={{ span: 16 }}
                    onFinish={onFinishRegister}
                    autoComplete="off">

                    <Form.Item name='name' label='Nombre'
                        rules={[{required: true}]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name='email' label='Email'
                        validateTrigger='onBlur'
                        rules={[{required: true, type: 'email'}]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name='phone' label='Móvil'
                        rules={[{required: true, type: 'phone'}]}>
                        <Input />
                    </Form.Item>

                    <Form.Item name='password' label='Contraseña'
                        rules={[
                            {
                                required: true,                                
                                message: 'La contraseña es un campo requerido',                                
                            },
                            {
                                min: 8,
                                message: 'Debe tener al menos 8 caracteres',
                            },
                            {
                                pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+={}\[\]:;"'<>,.?/~`|\\-]*$/,
                                message: 'Debe contener letras y números',
                            },
                            {
                                pattern: /^(?=.*[a-z])(?=.*[A-Z])/,
                                message: 'Debe contener mayúsculas y minúsculas',
                            },
                        ]}
                    // rules={[{ required: true }]}
                    >
                        <Input.Password autoComplete='new-password' />
                    </Form.Item>

                    <Form.Item name='confirm-password' label='Confirmar'
                        dependencies={['password']}
                        rules={[
                            {
                                required: true,
                                message: 'Confirmar la contraseña es un campo requerido'
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('La contraseña debe coincidir con el campo anterior'));
                                },
                            }),
                        ]}>
                        <Input.Password autoComplete='confirm-password' placeholder='Confirmar la contraseña' />
                    </Form.Item>
                    <br />

                    <ButtonLZ shape='round' type='primary' style={{ float: 'center' }} onClick={() => form.submit()} color={'yellow'} size='medium'>
                        {t('dictionary.register')}
                    </ButtonLZ>
                </Form>
                <br />
                {
                    formError && <Alert style={{ marginTop: '12px' }} type='error' message={errorMessage} />
                }
            </Card>            
        </div>
       
        <br/>
        <div><Link style={{color:'MediumBlue'}} to='/login'>Ir al LOGIN</Link></div>
        
        <FloatButton.Group
            trigger="click"    
            type="primary"        
            style={{
                right: 40,
                bottom: 40
            }}
            icon={<CustomerServiceOutlined />}
            >
            <FloatButton icon={<YoutubeFilled style={{color:'red'}} />} onClick={() => {setIsVideoDrawer(true)}}/>
            <FloatButton icon={<WhatsAppOutlined style={{color:'green'}} />} onClick={() => {window.open('https://api.whatsapp.com/send/?phone=34605319252&text&type=phone_number&app_absent=0', '_blank' );}}/>
        </FloatButton.Group>
        {isVideoDrawer && 
            <VideoIndexDrawer 
                videos={
                        [
                            {title:'LOCALIZA.ME en 1 minuto', url:'https://youtube.com/embed/pZYvBJnLC6A'},
                            {title:'Guía de Registro', url:'https://www.youtube.com/embed/fahu4iw3liU'}
                        ]
                    } 
                onFinish={() => {
                    setIsVideoDrawer(false);
                }}/>
            }
    </div>)
}
