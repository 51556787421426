export const WEBCONFIG_TYPE = {
  NON_CONFIGURABLE: 'NON_CONFIGURABLE',
  CONFIGURABLE_WITH_TEXT: 'CONFIGURABLE_WITH_TEXT',
  CONFIGURABLE: 'CONFIGURABLE',
  }

  export const WEBCONFIG_NAME = {
      HEADER: 'Cabecera',
      DESCRIPTION: 'Descripción',
      INTERIOR: 'Interiores',
      EXTERIOR: 'Exteriores',
      AT_WORK: 'En el trabajo',
      ADDITIONAL: 'Adicionales',
      REVIEWS: 'Reseñas',
      POSTS: 'Publicaciones',
      MAP: 'Mapa',
      FOOTER: 'Pie de página'
    }

    export const WEBCONFIG_WARNING = {
      HEADER: 'No hay foto de portada o logo',
      EXTERIOR: 'Para habilitar este bloque necesitas imágenes exteriores en tu cuenta de Google',
      INTERIOR: 'Para habilitar este bloque necesitas imágenes interiores en tu cuenta de Google',
      ADDITIONAL: 'No hay fotos adicionales',
      REVIEWS: 'No hay suficientes reseñas',
      CAROUSEL: 'No hay suficientes imágenes',
      MAP: 'Dirección no disponible',
      FOOTER: ''
      }