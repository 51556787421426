import { Card, Divider } from "antd"
import NormalSchedule from "./NormalSchedule"
import { SpecialSchedule } from "./SpecialSchedule"

export const RegularScheduleTab = ({ location }) => {
    return (
        <Card size="small">
            <NormalSchedule location={location} />
        </Card>
    )
}
