import React from 'react';
import { Form, Input} from 'antd';
import { useTranslation } from 'react-i18next';

const FormItemTitle = () => {		

  const { t } = useTranslation();	

  return (		
		<Form.Item
			label={<b>Título</b>}
			name='title'
			rules={[{ required: true, message: 'El título es obligatorio' }]}
		>
			<Input placeholder='El título aquí...'/>
		</Form.Item>							
	)
}

export default FormItemTitle;