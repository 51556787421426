import { PlusOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import { Form } from 'antd';
import { useContext, useState } from 'react';
import { Typography } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useAuth } from '../../../store/AuthContext/AuthContext';
import { GlobalVariableContext } from '../../../store/GlobalVariableContext/GlobalVariableContext';
import { getProjectStorage } from '../../../store/storageContext';
import { authFetch } from '../../../common/request/authFetch';
const { Text } = Typography;


const UploadInput = ({onFinish, selectedKeys, label, textBtn, category}) => {

  const { token, groupAccount } = useAuth();
  const [ formLogo ] = Form.useForm();
	const [uploadFileList, setUploadFileList] = useState([]);

  const {updateGlobalImage , setUpdateGlobalImage} = useContext(GlobalVariableContext)


  const onUploadChange = (info) => {
    if (info?.fileList.length > 0) {
      if (
        info?.fileList[0]?.size >= 5000000 ||
        info?.fileList[0]?.size <= 10000
      ) {
        message.error('Tamaño del archivo incorrecto', 3)
        return
      }
      if (
        info?.fileList[0]?.type !== "image/png" &&
        info?.fileList[0]?.type !== "image/jpeg" &&
        info?.fileList[0]?.type !== "image/jpg"
      ) {
        message.error('Extension de imagen incorrecta.', 3)
        return
      }
    }

    let fileList = [...info.fileList];
    fileList = fileList.slice(-1 * 1)
		fileList = fileList.map(file => {
			if (file.response) {
				file.url = file.response.url;
			}
			return file;
		});
		setUploadFileList(fileList);		
	}

	const uploadProps = {
		action: `${process.env.REACT_APP_API}/app/mediaItems/images`,
		accept: 'image/jpeg,image/png,image/jpg',
		headers: {
			Authorization: 'Bearer ' + token
		},		  
		onChange: onUploadChange,
		multiple: true,		 
		fileList: uploadFileList
	};

  	const submitMedia = async (values) => {
      try {
        const url = values.photo.fileList[0].url;
        const data = {
          category,
          photoURL: url,
          locationsNames: selectedKeys
        };

        await authFetch(`${process.env.REACT_APP_API}/projects/${getProjectStorage()}/${groupAccount}/locations/localPostMedia`, { method: 'POST', data });

        // await updatePics({ 
        //   config: { method: 'POST', data }, 
        //   type: "localPostMedia"
        // })
        message.success('El logotipo ha sido actualizado correctamente', 3)
        setUpdateGlobalImage(!updateGlobalImage);
        onFinish() 
        setUploadFileList([])
      } catch (error) {
        message.error('Problema inesperado al editar el logotipo', 3)
      }

    };

  return (
    <>
    <Form 
      layout='vertical' 
      form={formLogo} 
      onFinish={submitMedia}
      style={{display: 'flex', flexDirection: 'column', gap: '.5rem', justifyContent: 'center', alignItems: 'center', marginTop:'10px'}}
    >
            
      
	    <Form.Item 
        label={label} 
        name='photo'
        style={{textAlign: 'center'}}
      >							
		    <Upload {...uploadProps} listType='picture-card'>
			    {
            uploadFileList.length < 1 && (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>
                  Subir {category === 'LOGO' ? "Logo" : "Portada"}
                </div>
              </div>
                                          )
          }						
		    </Upload>
	    </Form.Item>    
      <Button 
        disabled={uploadFileList.length <= 0}
        shape='round' 
        type='primary' 
        onClick={formLogo.submit}
      >
		    {textBtn}
	    </Button>      
    </Form>
    </>
						
	)
};

export default UploadInput
