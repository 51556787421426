import { authRequest } from './authRequest';

export const authFetch = (url, config, callback) => {
  
  return authRequest(url, config)
  .then(() => {
      return Promise.resolve(true);      
  }).catch(() => {        
      return Promise.resolve(false);
    }
  );
}