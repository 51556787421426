import { useParams } from "react-router-dom"
import { useContext, useEffect, useState } from "react";
import { ClockCircleTwoTone, CheckCircleTwoTone,LoadingOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Card, Rate, Form, Tag, Input, message, Drawer, Button, Spin } from "antd";
import { Comment } from '@ant-design/compatible';
import { useTranslation } from "react-i18next";
import "../../../styles/ReviewsAndFaQ.css"
import { authRequest } from "../../../common/request/authRequest";
import { authFetch } from "../../../common/request/authFetch";
import { useAuth } from "../../../store/AuthContext/AuthContext";
import { STATUS } from "../../../common/constants/statusFAQAndReviews";
import ReviewComment from "../ReviewComment";
import ReviewAnswer from "../ReviewAnswer";
import { useFetch } from "../../../hooks/useFetch";
import { formatDate } from "../../../common/utils/formatDate";
import { truncateText } from "../../../common/utils/trucantedText";
import { GlobalVariableContext } from "../../../store/GlobalVariableContext/GlobalVariableContext";
import { useIsAuthorized } from "../../../common/constants/AuthUtils";
import IAFilled from '../../../assets/IA_logo.png';
import { Image } from "../../../../node_modules/antd/es/index";
import { ButtonLZ } from "../../../components/atoms/ButtonLZ";
import { LockFilled } from "../../../../node_modules/@ant-design/icons/lib/icons/index";

export const ReviewDetailDrawer = ({ selectedReview, onFinish }) => {


    const [status, setStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const {setPlansDrawer} = useContext(GlobalVariableContext);
    const isAuthorized = useIsAuthorized();

    const { t } = useTranslation();

    const [replyForm] = Form.useForm();

    const submitReply = async ({ comment }) => {

        const data = {
            comment,
            reviewId: review.name
        };
        await authFetch(
            `${process.env.REACT_APP_API}/app/reviews/reply`,
            { method: 'PUT', data: data }
        )
            .then((res) => {
                if (res) {
                    message.success(t('view.forms.modal.reviews.reply.success', { name: review.reviewerDisplayName }), 3);
                    setStatus('')
                    onFinish();
                } else {
                    message.error(t('view.forms.modal.reviews.reply.error', { name: review.reviewerDisplayName }), 3);
                }
            });
    };

    const getResponseIa = async () => {
        setIsLoading(true)
        await authRequest(`${process.env.REACT_APP_API}/app/ia?prompt=${encodeURIComponent(`
          Esta es la reseña real que tienes que responder: 
          Usuario a responder: \`\`\`${review?.reviewerDisplayName}\`\`\`
          Puntuación de reseña: \`\`\`${review?.starRating}\`\`\`
          Reseña a responder: \`\`\`${review?.comment?.originalText || review?.comment?.text}\`\`\``)}`, {}).then((data) => {
            handleOpenTextArea(data)
            setIsLoading(false)
        }).catch((error) => {
            setIsLoading(false)
        });
    }

    const handleOpenTextArea = (responseText) => {
        replyForm.setFieldsValue({ comment: responseText });
    };

    const { data: review } = useFetch(
        `${process.env.REACT_APP_API}/app/reviews/${selectedReview}`,
        {},
        [],
    );

    return (

        <Drawer
            onClose={() => onFinish()}
            placement='bottom'
            open={selectedReview}
            height='85%'
            style={{ backgroundColor: '#b6e7ff' }}
            closeIcon={null}
        >
            <>
                <h3 style={{color:'var(--primary)', margin:'10px 0px'}}>Responde a tu cliente</h3>
                <div style={{ borderRadius: '25px', marginTop: '10px'}}>
                    {review &&
                        <Card style={{ display: 'flex', flexDirection: 'column', gap: '10px', color: 'black' }} >

                            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <img src={review.reviewerProfilePhotoUrl} style={{ width: '35px', height: '35px' }} />
                                <div style={{ display: 'flex', gap: '5px', flexDirection: 'column', width: '100%' }}>
                                    <div style={{ display: 'flex', gap: '5px', width: '100%', justifyContent: 'space-between' }}>

                                        <b>{review.reviewerDisplayName}</b>
                                        <div style={{ display: 'flex', gap: '5px' }}>{review?.reviewReplyComment?.text ? <CheckCircleTwoTone style={{ fontSize: '18px' }} twoToneColor='#52c41a' />
                                            :
                                            <ClockCircleTwoTone style={{ fontSize: '18px' }} twoToneColor='#e8c246' />
                                        }
                                            <div className="email-date">{formatDate(review.createdTime)}</div></div>
                                    </div>
                                    <Rate disabled value={review.starRating} style={{ fontSize: '15px' }} />
                                </div>
                            </div>
                            <div>   
                                <p style={{ color: 'var(--grey)', fontSize: 13 }}>{truncateText(review.comment?.originalText || review.comment?.text || '')}</p>
                            </div>
                        </Card>}

                
              {review?.reviewReplyComment?.text && 
                <ReviewAnswer 
                  submitReply={submitReply} 
                  replyForm={replyForm} 
                  getResponseIa={getResponseIa} 
                  isLoadingIa={isLoading} 
                  review={review} 
                  onFinish={() => {onFinish()}}/>
              }
                        
              {!review?.reviewReplyComment?.text && status !== STATUS.REPLYING &&
                <a className='review-btn'
                  onClick={() => setStatus('Replying')}>
                  <span className='material-symbols-outlined review-btn-icon'>reply</span>
                  {t('dictionary.reply')}
                </a>} 
               {!review?.reviewReplyComment?.text && status === STATUS.REPLYING &&
                <div style={{ padding: '20px 20px 20px 25px' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <small style={{ color: 'grey' }}>{t('reviews.page.reply.review.ownerReply')}</small>                    
                  </div>
                  <Form layout='vertical'
                    form={replyForm}
                    onFinish={submitReply}
                  >
                    <Form.Item name='comment'>
                      <Input.TextArea
                        style={{ width: '100%', borderRadius: '15px' }}
                        className='input-field'
                        rows={6} />
                    </Form.Item>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center' }}>
                      {isAuthorized(['ROLE_APP_AI']) ?
                          <Button
                              onClick={() => {
                              if (!isLoading) {
                                  getResponseIa();
                              }
                              }}                      
                              type='link'
                              style={{ color: 'purple', borderColor: 'purple', backgroundColor: 'Lavender' }}>
                                  
                                  <Image preview={false} style={{height:'22px', width:'22px', opacity: 1}} src={IAFilled} /> 
                                  {isLoading ? <Spin indicator={<LoadingOutlined spin />} /> : <b>Sugerencia IA</b>}
                          </Button> 
                          :
                          <>
                              <Button                                            
                                  type='link'     
                                  onClick={() => setPlansDrawer(true)}                                                                               
                                  style={{ color: 'purple', borderColor: 'purple', backgroundColor: 'Lavender', marginBottom:'10px' }}>
                                      <span><LockFilled style={{fontSize:'18px'}}/>  <b>Asistente Virtual</b></span>
                              </Button>                                         
                          </>
                      }         
                      <div>                   
                          <ButtonLZ className='review-btn'                                    
                              type='link'
                              size='large'
                              style={{ backgroundColor: 'white', color: 'dodgerblue', borderColor: 'dodgerBlue', margin:'5px 3px', padding:'5px 14px' }}
                              onClick={() => { replyForm.resetFields(); setStatus(''); }}
                          >
                              <CloseOutlined /> 
                          </ButtonLZ>
                          <ButtonLZ className='review-btn'                                    
                            size='large'
                              style={{ margin:'5px  3px', padding:'5px 14px' }}
                              onClick={() => { replyForm.submit(); setStatus(''); }}
                          >
                              <CheckOutlined /> 
                          </ButtonLZ>
                      </div>
                    </div>
                  </Form>
                </div>}
                    
                </div>
            </>
        </Drawer>
    )
}