import { EditOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { EditWeb } from '../Web/EditWeb';
import { InfoView } from '../InfoView';
import { EditPhone } from './EditPhone';
import { PhoneView } from '../PhoneView';


export const Phone = ({ location }) => {
    
    const [edit, setEdit] = useState(false);

    return (
        <div>
            <span onClick={() => setEdit(true)}>
                <b className="title-box">Teléfono <EditOutlined /></b>
            </span>
            {
                edit ? (
                    <EditPhone
                        primaryPhone={location?.primaryPhone}
                        addPhones={location?.additionalPhones}
                        setEdit={setEdit}
                        edit={edit}
                    />
                ) : (
                    <PhoneView principal={location?.primaryPhone} secondaries={location?.additionalPhones} />                                            
                )
            }
        </div>
    )
}
