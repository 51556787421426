import { Button, Card, Divider, Drawer } from "antd"
import { LoginOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom"
import { useAuth } from "../../store/AuthContext/AuthContext";
import { ButtonLZ } from "../atoms/ButtonLZ";
import { getProjectStorage } from "../../store/storageContext";
import { useFetch } from "../../hooks/useFetch";
import { authFetch } from "../../common/request/authFetch";
import { useContext, useState } from "react";
import { GlobalVariableContext } from "../../store/GlobalVariableContext/GlobalVariableContext";
import logo from "../../assets/logo-blanco.png"
import googleLogo from "../../assets/logo_google.png"
import "../../styles/SideMenu.css"
import { PlansDrawer } from "../../pages/common/plans/PlansDrawer";
import { CheckCircleOutlined, CheckSquareFilled, CheckSquareOutlined, CloseSquareFilled, CloseSquareOutlined, GoogleCircleFilled, ShopFilled } from "../../../node_modules/@ant-design/icons/lib/icons/index";

export const SideMenuDrawer = ({ isMenuVisible, setIsMenuVisible }) => {

    const { logOut, profile } = useAuth();
    const {setPlansDrawer } = useContext(GlobalVariableContext)
    const {data : modules} = useFetch(`${process.env.REACT_APP_API}/app/modules`, {}, []);

    return (
        <>
        <Drawer
            closeIcon={null}
            onClose={() => setIsMenuVisible(null)}
            placement='right'
            width={'85%'}
            open={isMenuVisible}
            style={{ background: 'linear-gradient(to bottom, rgba(46, 199, 255, 1), rgba(5, 159, 255, 1))', color: 'white' }}
        >

            <div>
                <div style={{ display: 'flex', alignItems: 'center',
                    justifyContent:'center',
                    flexDirection:'column', 
                    gap: '15px' }}>

                    <img src={logo} style={{ height: '40px', width: 'auto' }}/>
                    <div className='container-logo'>
                        <div>
                            <span className='circle fw-bold'>
                                {profile.name && profile.name.toUpperCase().split('')[0]}
                            </span>                            
                        </div>
                        <div>
                        <h2>{profile?.name.substring(0, 15)}{profile?.name.length > 15 && '...'}</h2>
                        <br/>
                        <p>{profile?.email}</p>
                        </div>
                    </div>                                        
                </div>                    
                
                <Divider style={{ backgroundColor: 'white' }} />
                <p style={{marginBottom:'8px'}}><img src={googleLogo} style={{ height: '30px', width: 'auto' }} /> Cuenta de Google</p>                
                <b>{profile?.projects[0].platforms[0].platformAccount}</b>
                <Divider style={{ backgroundColor: 'white' }} />


                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>                                    
                    <div>                        
                        <p style={{ marginLeft: '30px' }}>
                            <p> 
                                <p style={{ fontSize: '20px' }}> {modules?.ai?.paid ? <CheckSquareFilled/> : <CloseSquareOutlined/>}<b>  Módulo IA</b></p> 
                                Tu asistente virtual
                            </p>
                            <br/>
                            <p> 
                                <p style={{ fontSize: '20px' }}> {modules?.web?.paid ? <CheckSquareFilled/> : <CloseSquareOutlined/>}<b>  Módulo WEB</b></p> 
                                Configurable por ti
                            </p>
                        </p>
                    </div>
                </div>
                
                <div className="upgrade-container">
                    <div>
                        <div className="upgrade" >
                            <h2>Mejorar Plan</h2>
                            <p>Toma el control del marketing digital de tu negocio</p>                            
                            <ButtonLZ onClick={() => {setPlansDrawer(true)}} style={{ background: 'linear-gradient(rgba(255, 165, 0, 0.8), rgba(255, 165, 0, 1))' }}><b>Ver módulos</b></ButtonLZ>                            
                        </div>
                    </div></div>
                <div 
                className="logout-container"
                onClick={() => { logOut() }}>
                    <LoginOutlined />
                    Cerrar sesión
                </div>
            </div>
        </Drawer>
        {/*plansDrawer && <PlansDrawer onFinish={() => {setPlansDrawer(false)}} />*/}
        </>
    )
}