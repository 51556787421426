import React from 'react';
import { InfoCircleTwoTone, MailTwoTone, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Card, Form, Input, message, Modal, Button } from 'antd';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import { useAuth } from '../../store/AuthContext/AuthContext';
import background from '../../assets/greyMap.png';
import "../../styles/Login.css"
import Selector from '../../components/atoms/language/Selector';
import { authFetch } from '../../common/request/authFetch';
import GoogleLoginButton from '../../components/atoms/GoogleLoginButton';
import { Link } from 'react-router-dom';

const Login = () => {
	const [formAuthError, setFormAuthError] = useState(false);
	const [serverError, setServerError] = useState({});
	const [user, setUser] = useState('');
	const location = useLocation();
	const navigate = useNavigate();
	const { logIn } = useAuth();
	const { t } = useTranslation();

	const onSubmit = (data) => {
		const state = location.state
		return logIn(data).then((res) => {
			if (res.success) {
				setServerError({})
				setFormAuthError(false);

				// state?.from?.pathname ?? <-- esto da error la primera vez que se inicia sesion
				return navigate(state?.from?.pathname ?? '/');
			}
			if (res.statusCode !== 401) {
				return setServerError({
					statusCode: res.statusCode,
					message: res.message
				})
			}
			return setFormAuthError(true)
		})
	};

	const sendRecoverEmail = async () => {
		return await authFetch(`${process.env.REACT_APP_API}/users/${user}/recover`, { method: 'POST' })
			.then((isOk) => {
				if (isOk) {
					return message.success(t('view.forms.modal.mail.success', { user }));
				} else {
					return message.error(t('view.forms.modal.mail.error', { user }));
				}
			}
			);
	}

	const [googleToken, setGoogleToken] = React.useState(null);

	const handleLoginSuccess = (response) => {
		try {
			console.log(response)
		  setGoogleToken(response.tokenId);
		} catch (error) {
		  console.error('Error al manejar éxito de inicio de sesión:', error);
		}
	  };
	  
	  const handleLoginFailure = (error) => {
		try {
		  console.log('Error al iniciar sesión con Google:', error);
		} catch (catchError) {
		  console.error('Error al manejar fallo de inicio de sesión:', catchError);
		}
	  };
	  

	return (
		<>
			<div>
				<div
					style={{ position: 'fixed', bottom: 0, right: 0, backgroundImage: `url(${background})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", width: '100%', height: '100vh', opacity: '0.5' }}/>
				</div>
				<div>
					<div className='login-general-two'>
						<Card bordered={true} className="login-card">
							<div className='login-form'>
								<div className='logo-header'>
								</div>

								<Form layout='vertical' name='basic' onFinish={onSubmit} autoComplete='off'
								style={{ width: '95%' }}
								>
									<Form.Item
										name='username'
										validateTrigger='onBlur'
										rules={[{
											required: true,
											type: 'email',
											message: t('login.user.required')
										}]}

									>
										<Input
											className='inputs'
											autoComplete='off'
											placeholder={t('login.user.placeholder')}
											onChange={(e) => setUser(e.target.value)}
											prefix={<UserOutlined />}
										/>
									</Form.Item>
									<Form.Item
										name='password'
										rules={[{
											required: true,
											message: t('login.password.required')
										}]}
									>
										<Input.Password
											className='inputs'
											autoComplete='off'
											placeholder={t('login.password.placeholder')}
											prefix={<LockOutlined />}
										/>
									</Form.Item>
									{formAuthError && <Alert message={t('view.forms.modal.login.error')} type="warning" showIcon />}
									<Button
										htmlType="submit"
										shape='round'
										width='30%'
										size='small'
										style={{ marginTop: '13px', backgroundColor: 'var(--accent)', border: 'black' }}
									>
										{t('login.button')}
									</Button>
								</Form> 

								{user ?
									<button
										className='missing-password'
										onClick={() => {
											Modal.confirm({
												title: <p>
													<Trans t={t} i18nKey="view.forms.modal.forgotPassword.title" values={{ user }} >
														¿Enviar correo de recuperación a <b style={{ color: 'dodgerblue' }}>{{ user }}</b>?
													</Trans>
												</p>,
												content: <p>{t('view.forms.modal.forgotPassword.content')}</p>,
												okText: t('view.forms.modal.send'),
												cancelText: t('view.forms.modal.cancel'),
												icon: <MailTwoTone />,
												onOk: () => sendRecoverEmail()
											});
										}}
									>
										{t('login.missingPassword')}
									</button>
									:
									<button
										className='missing-password'
										onClick={() => {
											Modal.info({
												title: t('view.forms.modal.forgotPassword.error'),
												icon: <InfoCircleTwoTone />
											});
										}}
									>
										{t('login.missingPassword')}
									</button>}


								<p style={{ color: '#a8cce3', marginLeft: '5px' }}>¿No tienes cuenta? <span style={{ color: 'white' }}><Link style={{color:'white'}} to='/register'>Regístrate</Link></span></p>

							</div>
						</Card>
						
					</div>
				</div>

				<div style={{ margin: '50px 10px', color:'black' }}>					
					<br/><br/>
					<h2>Mi <del style={{ color:'grey' }}>pequeño</del> GRAN negocio ...</h2>
					<br/>
					<div style={{ margin: '10px', display:'flex', justifyContent:'flex-end', color:'var(--primary-dark)'}}>
						<h2>LOCALIZA.ME</h2>															
					</div>
				</div>
		</>
	);
}

export default Login;