import { BellOutlined, SignalFilled, UserOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import React, { useContext, useEffect, useState } from 'react';

import { Client } from '@stomp/stompjs';

import "../../styles/CustomHeader.css"
import { SideMenuDrawer } from './SideMenuDrawer';
import { useFetch } from '../../hooks/useFetch';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { InformesDrawer } from './InfomesDrawer';
import { useAuth } from '../../store/AuthContext/AuthContext';
import { GlobalVariableContext } from '../../store/GlobalVariableContext/GlobalVariableContext';
import logo from '../../assets/logoBlueLocalizame.png';
import { Image } from '../../../node_modules/antd/es/index';
import { HomeFilled, HomeOutlined } from '../../../node_modules/@ant-design/icons/lib/icons/index';
import { ButtonLZ } from '../atoms/ButtonLZ';

const { Header } = Layout;

export const CustomHeader = (props) => {

    const location = useLocation();
    const navigate = useNavigate();

    const routeMappings = {
        "/": "",
        "/mybusiness": "Mi Negocio",
        "/reviews": "Reseñas",
        "/posts": "Publicaciones",
        "/posts/newPost": "Nueva Publicación",
        "/web": "Web",
        "/vincular": "Registro II"
    };

    const currentWord = routeMappings[location.pathname];

    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [informesDrawer, setInformesDrawer] = useState(false);
    //const [isMenuVisibleNot, setIsMenuVisibleNot] = useState(false);

    const { setProfile, profile } = useAuth();

    const { updateHeader} = useContext(GlobalVariableContext);
        
    useFetch(`${process.env.REACT_APP_API}/users/principal/profile`, {}, [updateHeader], (data) => {
        
        setProfile(data);        
        
        if ("REGISTERED" == data.status) {
			navigate('/vincular');
		} else if ("CONFIGURED" == data.status) {
			navigate('/vincular');
		} 
    });
    
    const ShowMenu = (props.isAuthenticated && props.viewHeader === undefined) || (props.viewHeader && props.isAuthenticated);

    return (
        <>
            <Header className='headerComponent'>

                    {/*// onClick={()=>{setInformesDrawer(true)}}
                    // style={{ display: 'flex', backgroundColor: 'var(--primary-light)', padding: '10px', height: '20px', borderRadius: '10px', alignItems: 'center' }}*/}                                       
                                        
                    <div>                        
                        <Image preview={false} style={{height:'40px', width:'40px', margin:'5px', opacity: 1}} src={logo} />
                    </div>
                        {/*<HomeFilled style={{ fontSize: '25px', margin:'10px', color:'var(--primary)', padding: '5px', borderRadius: '10px', background:'white' }} />*/}
                    
                    
                    {/*<h2 style={{color:'MidnightBlue'}}>{currentWord}</h2>*/}

                    {location.pathname === '/vincular' &&
                        <div><h2 style={{color:'MidnightBlue'}}>LOCALIZA.ME<div style={{fontSize:'10px'}}>Mi <del> Pequeño </del> GRAN negocio</div></h2></div>
                    }

                    {location.pathname !== '/' && location.pathname !== '/vincular' && location.pathname !== '/welcome' &&                    
                        <ButtonLZ size='small' onClick={() => {navigate('/')}} style={{ background: 'linear-gradient(rgba(255, 165, 0, 0.8), rgba(255, 165, 0, 1))' }}><b><HomeOutlined style={{ fontSize: '15px' }}/> Inicio</b></ButtonLZ>                                                                    
                    }
                    

                    <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>                       

                        {/* <div 
                        className='user-icon-container'> */}
                        {/* <UserOutlined style={{ fontSize: '18px' }} onClick={() => { setIsMenuVisible(true) }} />
                        <div className='line' style={{backgroundColor:data?.color}}></div> 
                        </div>*/}
                        <div className='container-circle-logo' onClick={() => { setIsMenuVisible(true) }}>
                            <span className='circle fw-bold'>
                                {profile?.name && profile?.name.toUpperCase().split('')[0]}
                            </span>
                        </div>
                    </div>
                   
                </Header >   
                            
                
            {isMenuVisible && <SideMenuDrawer setIsMenuVisible={setIsMenuVisible} isMenuVisible={isMenuVisible} />}
            {informesDrawer && <InformesDrawer setInformesDrawer={setInformesDrawer} informesDrawer={informesDrawer} />}

        </>
    )
};
