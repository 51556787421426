import { Form, message } from "antd";
import { EditableInput } from "../../../../../components/molecules/Forms/EditableInput";
import { useAuth } from "../../../../../store/AuthContext/AuthContext";
import { useTranslation } from "react-i18next";
import { authFetch } from "../../../../../common/request/authFetch";
import { getProjectStorage } from "../../../../../store/storageContext";

export const EditWeb = ({url, locationId, setEdit, edit, setCurrentURL}) => {

    const [webForm] = Form.useForm();
    const { t } = useTranslation();

    const submitWeb = async(values) => {
        const data = {
			field: 'WEB',		
			web: values.web
		};

      await authFetch(`${process.env.REACT_APP_API}/app/locations`, { method: 'PATCH', data: data })
      .then((res) => {

        if (res) {
          message.success('La web se ha editado correctamente', 3);
          setEdit(false);
          setCurrentURL(values.web);
        } else {
          message.error(t('Error inesperado al editar la web'), 3)
        }
      });
    
    }

    return (
        <div className="title-box">
            <Form layout='vertical' form={webForm} onFinish={(values) => submitWeb(values)}>
            <EditableInput
              form={webForm}
              value={url}
              field='web'
              onSubmitCallback={webForm.submit}
              edit={edit}
              setEdit={setEdit}
              />
          </Form>
        </div>
    )
}

