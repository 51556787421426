import { Swiper, SwiperSlide } from "swiper/react";
import { Image, Modal } from 'antd';
import { Pagination } from 'swiper/modules';

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./swiper.css";

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { authFetch } from "../../../common/request/authFetch";
import { GlobalVariableContext } from "../../../store/GlobalVariableContext/GlobalVariableContext";
import { message } from "../../../../node_modules/antd/es/index";

export default function SwiperCarousel({ mediaItems }) {

  const { t } = useTranslation();

  const {updateGlobalData, setUpdateGlobalData } = useContext(GlobalVariableContext)

  const [images, setImages] = useState(mediaItems);


  const deleteMedia = async (name) => {

    await authFetch(
      `${process.env.REACT_APP_API}/app/mediaItems/` + name,
      { method: 'DELETE' }
    ).then((res)=> {
      
        if (res) {
          const updatedMediaItems = mediaItems.filter((item) => item.name !== name);
          setImages(updatedMediaItems);      
          message.success('La imagen se ha borrado correctamente', 3);
          //setUpdateGlobalData(!updateGlobalData);
        } else {
          message.error(t('Error al borrar la imagen'), 3)
        }            
    })
  }

  return (
    <>      
        <Swiper
         pagination={{
          type: "fraction",
        }}
          modules={[Pagination]}
        >
          {
            images.map((mediaItem, index) => {
              return (
                <SwiperSlide key={index} style={{display:'flex', flexDirection:'column'}}>
                  <Image
                    key={index}
                    src={mediaItem.googleUrl}
                    className="swiper-slide-img"
                  />
                   <div style={{display:'flex', justifyContent:'flex-end', width:'100%', marginTop:'5px'}}>
                  <a

                    onClick={
                      () => {
                        Modal.confirm({
                          title: '¿Confirma el borrado de esta imagen?',
                          okText: t('dictionary.confirm'),
                          cancelText: t('dictionary.cancel'),
                          onOk: () => deleteMedia(mediaItem.name),
                        });
                      }
                    }>
                    <small>{t('dictionary.delete')}</small>
                  </a>
                  </div>
                </SwiperSlide>
              )
            })
          }
        </Swiper>              
    </>
  );
}
