import { InfoCircleOutlined } from '@ant-design/icons';
import { Checkbox, Collapse, Form, Input, Select } from 'antd';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

const FormItemCallToAction = () => {		

	const [isActionTypeRequired, setActionTypeRequired] = useState(false);
	const [isLocalURLSelected, setLocalURLSelected] = useState(false);
	const { t } = useTranslation();

  	return (		
		<>						
			<div key="1" style={{background:'#f5f9fb', padding:'15px', border:'1px solid lightgrey'}}>
				<b>Añadir acción</b>
				<small style={{marginLeft:'10px', color:'darkgrey'}}>
					Opcional
				</small>
			
				<Form.Item initialValue={''} 
				label='Tipo' 
				name='callToActionType' 
				rules={[{ required: false}]}			
				//</div>tooltip={{ title: 'El tipo de acción, eligen una de las opciones del desplegable', icon: <InfoCircleOutlined /> }}
				>
					<Select onChange={() => setActionTypeRequired(true)}>															
						<Select.Option value='BOOK'>
							{t('dictionary.book')}
						</Select.Option>
						<Select.Option value='ORDER'>
							{t('dictionary.order')}
						</Select.Option>
						<Select.Option value='SHOP'>
							{t('dictionary.shop')}
						</Select.Option>
						<Select.Option value='LEARN_MORE'>
							{t('dictionary.learnMore')}
						</Select.Option>
						<Select.Option value='SIGN_UP'>
							{t('dictionary.signUp')}
						</Select.Option>
						<Select.Option value='CALL'>
							{t('dictionary.call')}
						</Select.Option>
					</Select>
				</Form.Item>	
				<Form.Item 
					label='URL de destino' name='callToActionURL' 
					//tooltip={{ title:'URL del enlace de la acción', icon: <InfoCircleOutlined /> }}
					rules={[{ required: isActionTypeRequired && !isLocalURLSelected, type: 'url', message: 'La URL del enlace es obligatoría si se elige un tipo' }]
					}>
					<Input
					 disabled={!isActionTypeRequired || isLocalURLSelected} placeholder='URL de enlace para el establecimiento'/>
				</Form.Item>	
				{/* <Form.Item name='callToActionURLLocal' valuePropName="checked" style={{marginLeft:'15%'}}>
					<Checkbox disabled={!isActionTypeRequired} value={isLocalURLSelected} onChange={(e)=>{setLocalURLSelected(e.target.checked)}}>
						<i>
						    Usar la <b>web</b> de la ficha como URL de enlace
						</i>
					</Checkbox>
				</Form.Item>	 */}
			</div>	
		</>			
	)
}

export default FormItemCallToAction;