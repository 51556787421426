import { Button, Form, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

const EditableTextAreaItem = (props) => {

	const { t } = useTranslation();

	return (
		<Form.Item style={{ width: '100%' }}>
			<Input.Group compact style={{ width: '100%' }}>
				<Form.Item name={props.field} style={{ margin: '0px', width:'100%' }} rules={props.rules} initialValue={props.value}>
					<Input.TextArea
						rows={7}
						id={props.field + 'inputID'}
						onChange={(e) => { props.setEnabled(e.target.value != '' ? e.target.id : '') }}
						onFocus={(e) => { props.setEnabled(e.target.value != '' ? e.target.id : '') }}
						placeholder={t('view.forms.placeholder.input', { label: props.label })}
						disabled={!props.edit ? true : false}
					/>
				</Form.Item>
				{props.edit &&
					<Form.Item shouldUpdate style={{ marginTop: '15px' }}>
						{() => {
							return (
								<><Button
									disabled={props.enabled != props.field + 'inputID'}
									onClick={
										() => {
											Modal.confirm({
												title: t('view.forms.modal.single.title'),
												//content: t('view.forms.modal.single.content', { field: props.field }),
												okText: t('dictionary.confirm'),
												cancelText: t('dictionary.cancel'),
												onOk: props.onSubmitCallback
											});
										}
									}
									type="primary">
									{t('dictionary.save')}
								</Button>
									<Button
										style={{ background: 'white', color: 'dodgerblue', marginLeft: '20px' }}
										onClick={() => { 
											props.form.resetFields();
											props.setEdit(false) }}
										type="primary">
										{t('dictionary.cancel')}
									</Button>
								</>
							)
						}}
					</Form.Item>
					}
			</Input.Group>
		</Form.Item>
	)
}

export default EditableTextAreaItem;