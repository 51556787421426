import { Card, Divider } from "antd";
import { Name } from "./Name/Name";
import { Web } from "./Web/Web";
import { Phone } from "./Phone/Phone";
import { Description } from "./Description/Description";

const InfoTab = ({location}) => {
    return (
        <Card size="small">
            <Name location={location}/>
            <Divider style={{ margin: "15px 0px" }} />
            <Web location={location}/>
            <Divider style={{ margin: "15px 0px"  }} />
            <Phone location={location}/>
            <Divider style={{ margin: "15px 0px" }} />
            <Description location={location}/>
        </Card>
    );
};

export default InfoTab;