
import { Button, Card, Steps, message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { authRequest } from '../../common/request/authRequest';
import GoogleLogin from '../../components/organism/googleLoginButton/GoogleLogin';
import { useAuth } from '../../store/AuthContext/AuthContext';
import { CheckCircleTwoTone, CustomerServiceOutlined, WhatsAppOutlined, YoutubeFilled } from '../../../node_modules/@ant-design/icons/lib/icons/index';
import { GlobalVariableContext } from '../../store/GlobalVariableContext/GlobalVariableContext';
import { Alert, FloatButton, Skeleton, Spin, Tag } from '../../../node_modules/antd/es/index';
import { ButtonLZ } from '../../components/atoms/ButtonLZ';
import { VideoDrawer } from '../common/videos/VideoDrawer';
import { VideoIndexDrawer } from '../common/videos/VideoIndexDrawer';


export const Step2Register = () => {
    
    const navigate = useNavigate();    
    const { googleLogOut, profile } = useAuth();
    const { updateHeader, setUpdateHeader} = useContext(GlobalVariableContext);
    
    const { t } = useTranslation();

    const [googleEmail, setGoogleEmail] = useState();
    const [groupAccounts, setGroupAccounts] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isVideoDrawer, setIsVideoDrawer] = useState(false);

    useEffect(() => {

        if (profile.email) {
            setIsError(false);
        
            const platforms = profile.projects.filter(p => p.projectName === profile.email.replaceAll(".", "_"))[0].platforms;
            if (platforms.length === 1 && platforms[0].platformAccount != null) {
                setIsLoading(true);
                setGoogleEmail(platforms[0].platformAccount);
                authRequest(`${process.env.REACT_APP_API}/app/register/locations`, {})
                    .then((response) => {
                        setGroupAccounts(response);
                        setIsLoading(false);
                    }).catch((error) => {
                        setIsLoading(false)
                        setIsError(true);
                    });
            }
        }
    }, [profile]);

    const activateUser = () => {

        authRequest(`${process.env.REACT_APP_API}/users/principal?status=ACTIVE`, { method: 'PATCH' })
            .then(() => {
                message.success(t('Registro finalizado con éxito'));
                navigate('/welcome')
            }
            )
            .catch(() => {
                message.error(t('view.forms.register.sync.error'));
            })
        navigate('/')
    }

    const saveLocation = async (accountPath, locationPath) => {
        await authRequest(`${process.env.REACT_APP_API}/app/register/googleEmail/${googleEmail}/${accountPath}/${locationPath}/activate`, { method: 'POST' })
    }

    return (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop:'20px' }}>    
            
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <Card bordered={true} style={{ margin: '10px', width: '100%', minHeight: '90%', border: '.5px solid lightgrey', borderRadius: '10px', background: 'white' }}>
                <h3 style={{paddingBottom:'17px'}}>Registro: 2 de 2</h3>
                <div style={{ padding: '0 0 15px 0' }}>
                    <Steps current={1} status={"process"} >
                        <Steps.Step key={1} title={<small style={{color:'grey'}}>Usuario {profile.name}</small>} />
                        <Steps.Step key={2} title={<b>Vincula tu perfil Google</b>} />                        
                    </Steps>
                </div>                
                
                <div>
                    {googleEmail ?
                        <>
                            <Card>
                                
                                <CheckCircleTwoTone twoToneColor="#52c41a" style={{fontSize:'26'}}/><span style={{color:'grey'}}> Cuenta de Google:</span>

                                <b style={{ marginLeft: '8px' }}>  {googleEmail}</b>
                                <div style={{ paddingLeft: '50%', marginTop:'10px' }}>
                                    <Button shape='round' size='small' onClick={() => { googleLogOut(profile.email.replaceAll(".", "_")); setUpdateHeader(!updateHeader); }}>                                
                                        Desvincular
                                    </Button>
                                </div>
                            </Card>
                        </>
                        :
                        <>                        
                            <div style={{ margin: '10px' }}>                        
                                <h3>Prerequisito</h3>                    
                                <p style={{color:'grey'}}>¿Aún no tienes una cuenta de Google de tu negocio?</p>            
                                <a href='https://support.google.com/business/answer/2911778?hl=es-VE&co=GENIE.Platform%3DDesktop' target='blank'>Cómo agregar o reclamar tu Perfil de Negocio en Google</a>                                
                            </div>
                            <br/>
                            <div style={{ margin: '10px'}}>
                                <GoogleLogin
                                    project={profile.email}
                                    onFinish={() => {
                                        window.document.location.reload();
                                    }}
                                />                            
                            </div>                        
                        </>
                        }                        
                    </div>                    
                    
                    {groupAccounts ? 
                        <div style={{display:'flex', flexDirection:'column', gap:'20px', margin:'20px 0'}}>
                            <div style={{textAlign:'center'}}>
                                {groupAccounts.length > 0 ? 
                                <>
                                    <b style={{fontSize:'17px'}}>Confirma tu negocio:</b>
                                    {groupAccounts.map(groupAccount => {
                                        return (
                                            <>                                            
                                                {groupAccount.locations.map(location => {
                                                    return (
                                                        <div style={{padding:'10px 20px'}}>
                                                            <Button type="primary" shape='round' size='large' onClick={() => { 
                                                                saveLocation(groupAccount.id, location.id);
                                                                activateUser(); }}> 
                                                                <div>{location.name}</div>
                                                            </Button>
                                                        </div>
                                                        );
                                                })}                                            
                                            </>
                                        );
                                    })}   
                                </>         
                                :
                                <>  
                                    <Alert                                            
                                        description={<><b>No hay locales en esta cuenta. </b><br/><small>Desvincula esta cuenta y sigue las instrucciones</small></>}
                                        type="warning"
                                        showIcon/>
                                </>
                                }
                            </div>
                        </div>
                        :                      
                        <>
                            {isLoading && <Skeleton active style={{paddingTop:'20px'}}/>}                            
                            <br/>
                            {isError && 
                                <Alert description={<><b>Permisos insuficientes</b> <small>Vuelve a vincular la cuenta <span style={{fontSize:'30px'}}>🤓</span></small></>} type="warning" showIcon />                                
                            }
                        </>                    
                    }
            </Card>            
            </div>
            <FloatButton.Group
            trigger="click"    
            type="primary"        
            style={{
                right: 40,
                bottom: 40
            }}
            icon={<CustomerServiceOutlined />}
            >
            <FloatButton icon={<YoutubeFilled style={{color:'red'}} />} onClick={() => {setIsVideoDrawer(true)}}/>
            <FloatButton icon={<WhatsAppOutlined style={{color:'green'}} />} onClick={() => {window.open('https://api.whatsapp.com/send/?phone=34605319252&text&type=phone_number&app_absent=0', '_blank' );}}/>
        </FloatButton.Group>        
        {isVideoDrawer && 
            <VideoIndexDrawer 
                videos={
                        [
                            {title:'LOCALIZA.ME en 1 minuto', url:'https://youtube.com/embed/pZYvBJnLC6A'},
                            {title:'Guía de Registro', url:'https://www.youtube.com/embed/fahu4iw3liU'}
                        ]
                    } 
                onFinish={() => {
                    setIsVideoDrawer(false);
                }}/>
            }
        </div>  
    )
}