import { Period } from "../../../pages/Location/LocationTabs/Schedule/components/Period"

export const RegularDayScheduleView = ({ title, regularHour }) => {

    return (
        <div style={{ display: "flex", justifyContent:'space-between'}}>
            <p style={{ width: "150px" , color:'#666', textAlign:'start'}} >
                {title}
            </p>
            <div style={{display: "flex", flexDirection:'column', alignItems:'flex-end', marginTop:'10px'}}>
                {
                    regularHour.length === 0
                        ? 
                            <b style={{color:'LightSalmon'}}>Cerrado</b>
                        : 
                            regularHour.map((data, i) => (
                                <Period data={data} key={i} />
                            ))
                }
            </div>
        </div>

    )
}
