import moment from "moment";

export function calculateDays(date) {

    const days = moment().diff(moment(date), 'days');

    if (days >= 7) {
        const semanas = Math.floor(days / 7);
        return `Hace ${semanas} semana${semanas > 1 ? 's' : ''}`;
    } else if (days >= 1) {
        return `Hace ${days} día${days > 1 ? 's' : ''}`;
    } else {
       return '';
    }
}
