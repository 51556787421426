import { Button } from "antd"

export const ButtonLZ = ({disabled, children, onClick, ghost, type="primary", color, size, style}) => {
    return (
        <Button
        onClick={onClick}
        style={style}
        type={type}
        shape="round"
        color={color}
        size={size}
        ghost={ghost}
        disabled={disabled}
        >
            {children}
        </Button>
    )
}