import { Button } from 'antd';
import React, { useState } from 'react';
import { GoogleOutlined } from '@ant-design/icons';
import { useGoogleLogin } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../store/AuthContext/AuthContext';
import { SyncOutlined } from '../../../../node_modules/@ant-design/icons/lib/icons/index';

const GoogleLogin = ( {project, onFinish, onCancel} ) => {

  const { googleLogIn } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const onLogIn = (data) => {

    setIsLoading(true);
    googleLogIn(data, project.replaceAll(".","_")).then((res) => {
      
      if (res) {
        onFinish();
      }
    })
    setIsLoading(false);
  }
  
  const onFailure = (error) => {
    console.log(`Login error: ${error}`)
  }

  const login = useGoogleLogin({
    onSuccess: onLogIn,
    onError: onFailure,
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/business.manage'
  });

  return (
      <Button
        type="primary"
        size='large'
        onClick={login}
        icon={isLoading ? <SyncOutlined spin/> : <GoogleOutlined />}
      >
        Vincula tu negocio
      </Button>
  )
}

export default GoogleLogin;