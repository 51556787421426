import { Button, Form, Input, Modal } from 'antd';
import { FormInstance } from 'antd/es/form';
import { Rule } from 'antd/lib/form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const EditableInput = ({
	field,
	label,
	rules,
	value,
	form,
	setEdit,
	edit,
	onSubmitCallback
}) => {

	const [enabled, setEnabled] = useState('');

	const fieldId = field + 'ID';
	const { t } = useTranslation();


	return (
		<Form.Item>
			<Input.Group compact style={{display:'flex', flexDirection:'column'}}>
				<Form.Item name={field} style={{ margin: '0px', width: '80%' }} rules={rules} initialValue={value} >
					<Input
						id={fieldId}
						onChange={(e) => { setEnabled(e.target.value !== '' ? e.target.id : '') }}
						onFocus={(e) => { setEnabled(e.target.value !== '' ? e.target.id : '') }}
						placeholder={label ? t('view.forms.placeholder.input', { label: label }) : ''}
						disabled={!edit ? true : false} />
				</Form.Item>
				{edit &&
					<Form.Item shouldUpdate style={{ marginTop: '15px' }}>
						{() => {
							return (
								<><Button
									disabled={
										!!form.getFieldsError().filter(({ errors }) => errors.length).length
										|| enabled !== fieldId}
									onClick={() => {
										Modal.confirm({
											title: t('view.forms.modal.single.title'),
											//content: t('view.forms.modal.single.content', { field: field }),
											okText: t('dictionary.confirm'),
											cancelText: t('dictionary.cancel'),
											onOk: onSubmitCallback
										})
									}}
									type="primary">
									{t('dictionary.save')}
								</Button>
									<Button
										style={{ background: 'white', color: 'dodgerblue', marginLeft: '20px' }}
										onClick={() => {
											form.resetFields();
											setEdit(false);
										  }}
										type="primary">
										{t('dictionary.cancel')}
									</Button>
								</>
							)
						}}
					</Form.Item>}
			</Input.Group>
		</Form.Item>
	)
}