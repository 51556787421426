import { Button, Card, Image, Spin, Tabs, Tag } from "antd";
import { useFetch } from "../../hooks/useFetch";
import { CheckOutlined, WarningOutlined, EditOutlined, LoadingOutlined } from "@ant-design/icons"
import { t } from 'i18next';
import "../../styles/Location.css"
import { useContext, useState } from "react";
import UpdtPicsModal from "./modal/UpdtPicsModal";
import InfoTab from "./LocationTabs/Info/InfoTab";
import { RegularScheduleTab, ScheduleTab } from "./LocationTabs/Schedule/RegularScheduleTab";
import { GlobalVariableContext } from "../../store/GlobalVariableContext/GlobalVariableContext";
import { Skeleton } from "../../../node_modules/antd/es/index";
import { CategoryTab } from "./LocationTabs/Category/Categories/CategoryTab";
import { SpecialScheduleTab } from "./LocationTabs/Schedule/SpecialScheduleTab";
import { PhotosTab } from "./LocationTabs/Photos/PhotosTab";

export const Location = () => {

    const [uploadPic, setUploadPic] = useState(false)
    const {updateGlobalData } = useContext(GlobalVariableContext)

    const { data, isLoading } = useFetch(
        `${process.env.REACT_APP_API}/app/locations`,
        {},
        [updateGlobalData]
    );

    const { data: media, isLoading: mediaLoading } = useFetch(
        `${process.env.REACT_APP_API}/app/mediaItems`,
        {},
        []
    );

    const cardStyle = {
        backgroundImage: `url(${media?.portada?.googleUrl})`,
        position:'relative'
    };

    return <div>

        {data && <UpdtPicsModal
            visible={uploadPic}
            handleVisible={setUploadPic}
            locationId={data.name}
            textBtn={'Publicar'}
        />
        }
        {/*<h3 style={{color:'var(--primary)'}}>Mantén la ficha actualizada <span style={{fontSize:'30px'}}>🧐</span></h3>*/}
        <Card className="bg-card-location" style={cardStyle} >

            {/* <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div className='container-verify'>
                    {data?.status === 'HAS_VOICE_OF_MERCHANT' ? <p>Verificado</p> : <p>No Verificado</p>}
                </div>

                <h1>{data?.title}</h1>
                <h3>{data?.mainCategory}</h3>
            </div> */}
            <div
                onClick={() => setUploadPic(true)}
                style={{ position: 'absolute', top: '5%', right: '2%', fontSize: '18px', background:'white', borderRadius:'50%', height:'30px', width:'30px', display:'flex', alignItems:'center', justifyContent:'center' }}><EditOutlined /></div>

            <div style={{ display: 'flex', gap: '35px', position: 'absolute',top: '80%', left: '6%' }}>
                <div className='logo-location'>
                    {mediaLoading ? 
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> 
                        :
                        <Image
                            style={{ cursor: 'pointer', borderRadius: '50%', padding: '1px', background:'ghostwhite' }}
                            width={75}
                            preview={false}
                            src={media?.logo?.googleUrl}
                            referrerPolicy="no-referrer" />
                    }
                </div>
            </div>
        </Card>

        <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column', gap: '5px' }}>
           {data.addressLine == null ? 
                <i style={{color:'grey', marginRight:'8px'}}>Sin ubicación</i> 
                : 
                <>
                    <small>
                        {data.locality}, {data.administrativeArea}
                    </small> 
                    <small>
                        {data.addressLine}
                    </small>
                </>
            }
            <Tag color={data?.status === 'HAS_VOICE_OF_MERCHANT' ? "green" : "error"}>{data?.status === 'HAS_VOICE_OF_MERCHANT' ? <small><CheckOutlined/> Verificada</small> : <p><WarningOutlined/></p>}</Tag>
        </div>
        
        {data && <div style={{ padding: '0px 20px' }}>

            {isLoading ?
                <Skeleton/>
                :
                <Tabs defaultActiveKey="1" type="line">

                    <Tabs.TabPane
                        tab={<span>Info</span>}
                        key="1">
                        <InfoTab location={data} />
                    </Tabs.TabPane>

                    <Tabs.TabPane
                        tab={<span>Categoría</span>}                    
                        key="2">
                        <CategoryTab location={data} />
                    </Tabs.TabPane>

                    <Tabs.TabPane
                        tab={<span>Fotos</span>}
                        key="3">
                        <PhotosTab location={data} mediaItems={media?.list} />
                    </Tabs.TabPane>

                    <Tabs.TabPane
                        tab={<span>Horario</span>}
                        key="4">
                        <RegularScheduleTab location={data}/>
                    </Tabs.TabPane>

                    <Tabs.TabPane
                        tab={<span>Festivos</span>}
                        key="5">
                        <SpecialScheduleTab location={data}/>
                    </Tabs.TabPane>
                </Tabs>
            }
        </div>}
    </div>

}

