import { Card, Divider } from "antd";
import { Categories } from "./Categories";
import { ServicesItems } from "../Services/ServiceItems";

export const CategoryTab = ({ location }) => {
    return (
        <Card size="small">
            <Categories location={location} />
            <Divider style={{ margin: "15px 0px" }} />
            <ServicesItems location={location}/>
        </Card>
    );
};
