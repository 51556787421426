import { ConfigProvider, Layout as AntdLayout } from 'antd';
import { useTranslation } from 'react-i18next';
import es_ES from "antd/lib/locale/es_ES";
import en_GB from "antd/lib/locale/en_GB";
import 'moment/locale/es';
import React, { useContext } from 'react';
import MainContent from './MainContent'
import { PlansDrawer } from '../pages/common/plans/PlansDrawer';
import { GlobalVariableContext } from '../store/GlobalVariableContext/GlobalVariableContext';
import { InfoDrawer } from '../pages/Register/InfoDrawer';

const MainLayout = () => {

  const {plansDrawer, setPlansDrawer, infoDrawer, setInfoDrawer} = useContext(GlobalVariableContext)
  const { i18n } = useTranslation();

  const antdLocales = {
    es: es_ES,
    en: en_GB
  }

  return (
    <AntdLayout>
      <ConfigProvider locale={es_ES} 
      theme={{
        token: {
          colorPrimary: '#4094d0',
          fontFamily: 'var(--font-family)',
        },
      }}
      >
      <MainContent />
      {plansDrawer && <PlansDrawer onFinish={() => {setPlansDrawer(false)}}/>}
      {infoDrawer && <InfoDrawer onFinish={() => {setInfoDrawer(false)}}/>}
      </ConfigProvider>
    </AntdLayout>
  )
};

export default MainLayout;