import { Tag } from "antd"

export const PhoneView = ({principal, secondaries}) => {
    return(
        <div style={{ marginLeft:'15px' }}>
        <div >
            <div style={{ color: "#666" }}>
                {principal && <>{principal} <Tag>Principal</Tag></>}
            </div>
        </div>
        {secondaries?.map((additionalPhone, i) => (
            <div style={{ color: "#666" }} key={i}>
            {additionalPhone}
            </div>
        ))}
                            
      </div> 
    )
}