import axios from 'axios';
import { getTokenForStorage } from '../../store/storage';
import { useAuth } from '../../store/AuthContext/AuthContext';

export const authRequest = (url, config) => {

  const jwtToken = getTokenForStorage();

  const headers = !!jwtToken? {
    ...config.headers,
    Authorization: 'Bearer ' + jwtToken
  }:{};

  const promise = axios.request({
    ...config,
    headers,
    url
  }).then((response) => {
    return response.data;    
  }).catch((error) => {          
    throw error.response;
  });
  
return promise;
}