import { Image, Modal, Tag } from "antd";
import { useFetch } from "../../hooks/useFetch";
import { authFetch } from "../../common/request/authFetch";
import { useTranslation } from "react-i18next";
import { POSTTYPES } from "../../common/constants/postTypes";
import { formatDate } from "../../common/utils/formatDate";
import { ButtonLZ } from "../../components/atoms/ButtonLZ";
import { Link } from "react-router-dom";
import { message } from "../../../node_modules/antd/es/index";
import { useState } from "react";

export const Posts = () => {

    const { t } = useTranslation();
    const [updateData, setUpdateData] = useState(false);
    const { data, isLoading } = useFetch(`${process.env.REACT_APP_API}/app/posts`, {}, [updateData], );

    const deletePost = async(value) => {
        await authFetch(`${process.env.REACT_APP_API}/app/posts?locationsId=${value.name}` , { method: 'DELETE'})
        .then((res) => {
            if (res) {
                setUpdateData(!updateData)     
                message.success('La publicación se ha borrado correctamente', 3);
              } else {
                message.error(t('Error al borrar la publicación'), 3)
              }  
        })
    }

    return (
       <div style={{margin:'0px 15px'}}>
            <div style={{display:'flex', justifyContent:'center', margin:'20px 10%'}}>             
                <Link to='/posts/newPost'><ButtonLZ size={'large'}>Publicar</ButtonLZ></Link>
            </div>
            <h3 style={{color:'var(--primary)'}}>Tus clientes quieren saber de ti <span style={{fontSize:'30px'}}>📡</span></h3>
            
            {data?.map((p) => <div style={{ margin: '20px 0px' }}>
                <div style={{ fontSize: '16px', display: 'block', border: "1px solid #80808033", borderRadius: '20px 20px 0px 0px' }}>
                    {
                        p?.mediaItemsURL?.map((googleUrl) => {
                            return (<Image width={'100%'} src={googleUrl} preview={false} style={{ borderRadius: '20px 20px 0px 0px', display: 'inline' }} />)
                        })
                    }
                </div>
                <div style={{ padding: '15px', textAlign: 'justify', background: 'white', color: 'rgba(0, 0, 0, 0.5)', border: "1px solid #80808033",  borderRadius: '0px 0px 20px 20px'}}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
                        <Tag style={{ borderRadius: '20px' }} color={'blue'}>{POSTTYPES[p?.topicType]}</Tag>
                        <small style={{fontWeight:700}}>{formatDate(p?.createTime)}</small>
                    </div>
                    <p>{p?.summary}</p>

                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px'}}>
                        <a style={{color:'salmon'}}

                            onClick={
                                () => {
                                    Modal.confirm({
                                        title: '¿Confirma el borrado de esta publicación?',
                                        okText: t('dictionary.confirm'),
                                        cancelText: t('dictionary.cancel'),
                                        onOk: () => deletePost(p),
                                    });
                                }
                            }>
                            <p>Borrar</p>
                        </a>
                        <a target={'_blank'} href={p.searchUrl}><b>Ver en Google {'>>>'}</b></a>
                    </div>
                </div>
            </div>)}
        </div> )
}

