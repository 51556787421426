import { Drawer } from "../../../../node_modules/antd/es/index"

                               
export const WebPreview = ({webId, onFinish}) => {

    return (
        <Drawer
            closeIcon={null}        
            placement='bottom'
            onClose={() => onFinish()}            
            height={'90%'}
            open={true}            
            bodyStyle={{ border: '0', padding: '0' }}
        >                        
            <iframe width='100%' height='100%' src={`${process.env.REACT_APP_SITES}/web/${webId}`} title="Mi Web" style={{ border: '0', padding: '0' }}></iframe>

        </Drawer>
    )
}