import { Tag } from "antd";
import { Tooltip } from "../../../../../../node_modules/antd/es/index";
import { InfoCircleOutlined } from "../../../../../../node_modules/@ant-design/icons/lib/icons/index";

export const ServiceItemsView = ({ serviceItems }) => {

    return (
        <div>
            {
                serviceItems?.serviceItems.map(
                    (serviceItem, i) => (

                        <div style={{display:'flex'}}>
                            <div className="title-box">
                                <div style={{ display: "flex", justifyContent: "flex-start", marginLeft: '5px' }}>
                                    <span style={{ color: "#666" }}>
                                        {serviceItem.name}
                                    </span>
                                </div>
                            </div>
                            <Tooltip title={
                                <>
                                <div>
                                {!serviceItem.free ? <i>* Campo Libre</i> : <i>* Campo predeterminado</i> }
                                <br/><br/>
                                </div>                            
                                    <b>Categorías padre:</b>
                                    <div>
                                        {serviceItem.categories && serviceItem.categories.map((cat, i) => (<p>{cat}</p>))}                            
                                    </div>
                                </>
                            }>
                            <InfoCircleOutlined />
                            </Tooltip>
                        </div>
                    ))
            }
        </div>
    );
}



